import { Outlet } from 'react-router-dom'
import { UserConsumer } from '../lib/user_context'
import NoMatch from '../pages/NoMatch'


export default function AdminProtectedRoute() {
    // const token = useAuth()
    // const isAdmin: boolean = useMemo(() => {
    //     if (token !== null && token !== undefined) {
    //         const decodedToken = JSON.parse(JSON.stringify(jwtDecode(token!)))
    //         return decodedToken.role === "admin"
    //     }
    //     return false
    // }, [token])

    // return isAdmin ? <Outlet /> : <Navigate to='/' />
    return <UserConsumer>
        {
            (user) => {
                if (user.role === "admin") {
                    return <Outlet />
                }
                return <NoMatch />
            }
        }
    </UserConsumer>
}
