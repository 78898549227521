import { Edit } from '@mui/icons-material'
import { Alert, Box, Button, Card, Chip, CircularProgress, Divider, Grid, Icon, Tooltip, Typography, useTheme } from '@mui/material'
import { DataGrid, GridActionsCellItem, GridColumns } from '@mui/x-data-grid'
import moment from 'moment'
import { useSnackbar } from "notistack"
import { useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import Layout from '../layouts/MainLayout'
import AcceptOrderDialog from '../components/UpdateOrder/AcceptOrderDialog'
import CancelOrderDialog from '../components/UpdateOrder/CancelOrderDialog'
import DispatchOrderDialog from '../components/UpdateOrder/DispatchOrderDialog'
import HoldOrderDialog from '../components/UpdateOrder/HoldOrderDialog'
import UpdatePaymentInfoDialog from '../components/UpdateOrder/Meta/UpdatePaymentInfoDialog'
import UpdateProductDialog from '../components/UpdateOrder/Meta/UpdateProductDialog'
import UpdateShippingInfoDialog from '../components/UpdateOrder/Meta/UpdateShippingInfoDialog'
import UpdateTransitStatusDialog from '../components/UpdateOrder/UpdateTransitStatusDialog'
import Order from '../interface/order'
import Api from '../lib/api'
import UserContext, { UserConsumer } from '../lib/user_context'
import { detectOrderStatus, orderSourceCodeToString, orderStatusToIcon } from '../lib/util'

interface LabelLineProps {
    title: string
    value: string | number
}

function LabelLine({ title, value }: LabelLineProps) {
    return <Box display='flex'>
        <Typography sx={{ fontWeight: 'bold', marginRight: 1 }}>{title}</Typography>
        <Typography>{value}</Typography>
    </Box>
}

const haveRequiredPermission = (permissionsArray: string[], requiredPermissions: string[]): boolean => {
    let havePermissions = false;
    for (let index = 0; index < requiredPermissions.length; index++) {
        const element = requiredPermissions[index];
        if (permissionsArray.includes(element)) {
            havePermissions = true
            break
        }
    }
    return havePermissions
}

export default function UpdateOrder() {
    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const theme = useTheme()
    const params = useParams()
    const userData = useContext(UserContext)
    const [orderEditable, setOrderEditable] = useState(false)
    const [orderInfo, setOrderInfo] = useState<Order | undefined>()
    const [processing, setProcessing] = useState(true)
    const [drawerState, setDrawerState] = useState<{
        open: boolean,
        action: string,
        data?: any
    }>({
        open: false,
        action: '',
    })
    // const [repeatCustomerState, setRepeatCustomerState] = useState({
    //     checking: false,
    //     result: undefined,
    //     checked: false
    // })
    const orderStatus = useMemo(() => detectOrderStatus(orderInfo), [orderInfo]);
    const orderChip = useMemo(() => {
        const key = orderSourceCodeToString(orderInfo?.order_source)
        const icon = orderStatusToIcon(key)
        return <Chip color={orderInfo?.order_source === 'ARA-AD' ? 'success' : orderInfo?.order_source === 'ARA_WEB' ? 'info' : orderInfo?.order_source === 'ARA-IOS' ? 'warning' : 'default'} icon={<Icon>{icon}</Icon>} label={key} />
    }, [orderInfo])

    const productGridColumn: GridColumns = useMemo(() => {
        const cols: GridColumns = [
            {
                field: 'product_image', filterable: false, sortable: false, headerName: '', description: 'Product Image', width: 80, renderCell: (cell) => {
                    return <img src={cell.row.tbl_product.tbl_products_gallary.path} alt="Product" height={60} width={60} />
                }
            },
            {
                field: 'name', filterable: false, sortable: false, headerName: 'Product', description: 'Name of the product', flex: 1, renderCell: (cell) => {
                    return <Tooltip title={cell.row?.tbl_product?.name?.split('-')[0] ?? 'N/A'}>
                        <Typography variant='body2' noWrap>{cell.row?.tbl_product?.name?.split('-')[0] ?? 'N/A'}</Typography>
                    </Tooltip>
                }
            },
            {
                field: 'item_qty', filterable: false, sortable: false, headerName: 'Qty', description: 'Quantity of the product', width: 60, type: 'number'
            },
            {
                field: 'paid_amount', filterable: false, sortable: false, headerName: 'Amount', description: 'Amount paid by the customer for this product', width: 80, type: 'number'
            },

        ]
        if (orderStatus.key === "pending" && orderEditable && userData?.permissions?.includes("v3_action_new_edit_order_meta_info")) {
            cols.push({
                field: 'actions', type: 'actions', width: 80, getActions: (params: any) => [
                    <GridActionsCellItem icon={<Edit />} label="Edit product details" onClick={_ => {
                        // console.log(params.row)
                        setDrawerState({
                            open: true,
                            action: 'product',
                            data: params.row
                        })
                    }} />
                ]
            })
        }
        return cols;
    }, [orderStatus.key, userData?.permissions, orderEditable])
    const apiHelper = useMemo(() => new Api(), [])
    const getData = useMemo(() => async () => {
        const response = await apiHelper.fetchOrdersByStatus({
            type: "all", filter: {
                orderId: parseInt(params?.oid as string ?? "")
            }
        })
        // console.log(response)
        if (response.ok) {
            setProcessing(false)
            setOrderInfo(response.data?.data[0])
            // console.log(moment().diff(response.data?.data[0].created_at, 'months'))
            if (moment().diff(response.data?.data[0].created_at, 'months') < 1) {
                setOrderEditable(true)
            }
        } else {
            setProcessing(false)
            showSnackbar(response.message, {
                variant: 'error'
            })
        }
    }, [apiHelper, params, showSnackbar])

    useEffect(() => {
        getData()
    }, [getData])

    // console.log(orderStatus.key)

    // const makeField = (field: ARInput) => {
    //     if (type === 'select') {
    //         return <FormControl fullWidth>
    //             <InputLabel id="courier_partner_id_label">{field.label}</InputLabel>
    //             <Select
    //                 labelId="courier_partner_id_label"
    //                 id="courier_partner_id"
    //                 // value={age}
    //                 label={field.label}
    //             // onChange={handleChange}
    //             >
    //                 <MenuItem value={10}>Ten</MenuItem>
    //                 <MenuItem value={20}>Twenty</MenuItem>
    //                 <MenuItem value={30}>Thirty</MenuItem>
    //             </Select>
    //         </FormControl>
    //     }
    // }

    const handleDialogClose = () => {
        setDrawerState({
            open: false,
            action: ''
        })
    }

    return (
        <Layout title='Update Order'>
            {
                processing ? <Grid container justifyContent='center' alignItems='center' sx={{
                    height: `calc(90vh - ${theme.mixins.toolbar.minHeight}px)`
                }}>
                    <Grid item textAlign='center' >
                        <CircularProgress />
                        <Typography sx={{
                            marginTop: 2
                        }}>Loading Details...</Typography>
                    </Grid>
                </Grid> : orderInfo === undefined ? <Alert variant='outlined' severity='error'>There's no information about this order with current criteria in our system!</Alert> : <Box>
                    <Grid container spacing={2}>
                        <Grid item sm={12} md={6} xl={4} width="100%">
                            <Card variant='outlined'>
                                <Typography sx={{ padding: 1 }} variant="h5">Order Information</Typography>
                                <Divider />
                                <Box padding={1}>
                                    <LabelLine title='Order Id:' value={orderInfo.orderid.toString()} />
                                    <LabelLine title='CRM Id:' value={orderInfo.tbl_order_room?.crmid ?? 'N/A'} />
                                    <LabelLine title='Amount:' value={`₹${orderInfo.order_meta?.map(e => e.paid_amount).reduce((a, b) => a + b, 0) ?? 'N/A'}`} />
                                    <LabelLine title='Total Items:' value={orderInfo.order_meta?.length ?? 0} />
                                    <LabelLine title='Ordered At:' value={moment(orderInfo.created_at).format('DD/MM/YYYY HH:mm A')} />
                                    <LabelLine title='PhonePe PaymentID:' value={orderInfo.phonepay_payment_id ?? "N/A"} />
                                    <LabelLine title='PhonePe MerchantID:' value={orderInfo.phonepay_merchant_id ?? "N/A"} />
                                </Box>
                                <Divider />
                                <Box padding={1} display='flex' alignItems='center'>
                                    <LabelLine title='Source: ' value={''} />
                                    {
                                        orderChip
                                    }
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item sm={12} md={6} xl={4} width="100%" >
                            <Card variant='outlined'>
                                <Box display='flex' padding={1}>
                                    <Typography sx={{ flex: 1 }} variant="h5">Ship To</Typography>
                                    <UserConsumer>
                                        {(data) => {
                                            return orderStatus.key === "pending" && orderEditable && data.permissions?.includes("v3_action_new_edit_shipping_info") ? <>
                                                <Button onClick={_ => setDrawerState({ action: 'editShippingInfo', open: true })}>Edit</Button>
                                                <UpdateShippingInfoDialog open={drawerState.open && drawerState.action === 'editShippingInfo'} apiHelper={apiHelper} order={orderInfo} onClose={handleDialogClose} />
                                            </> : <></>
                                        }}
                                    </UserConsumer>
                                    {/* <Tooltip title='Edit shipping information'>
                                        <IconButton size='small'><Edit fontSize='small' /></IconButton>
                                    </Tooltip> */}
                                </Box>
                                <Divider />
                                <Box padding={1}>
                                    <LabelLine title='Name:' value={orderInfo.user_info?.fullname ?? 'N/A'} />
                                    <LabelLine title='Phone:' value={orderInfo.user_info?.phone?.toString() ?? 'N/A'} />
                                </Box>
                                <Divider />
                                <Box padding={1}>
                                    <LabelLine title='Address:' value={`${orderInfo.user_info?.address_line_1 ?? ''},${orderInfo.user_info?.address_line_2 ?? ''}`} />
                                    <LabelLine title='City:' value={orderInfo.user_info?.city ?? 'N/A'} />
                                    <LabelLine title='District:' value={orderInfo.user_info?.district ?? 'N/A'} />
                                    <LabelLine title='State:' value={orderInfo.user_info?.state ?? 'N/A'} />
                                    <LabelLine title='Pincode:' value={orderInfo.user_info?.pincode ?? 'N/A'} />
                                </Box>
                            </Card>
                        </Grid>
                        {/* <Grid item sm={12} md={6} xl={4} width="100%" >
                            <Card variant='outlined'>
                                <Box display='flex' padding={1}>
                                    <Typography sx={{ flex: 1 }} variant="h5">Customer Information</Typography>
                                    <UserConsumer>
                                        {(data) => {
                                            return orderStatus.key === "pending" && data.permissions?.includes("v3_action_new_edit_customer_info") ? <>
                                                <Button onClick={_ => setDrawerState({ action: 'editCustomerInfo', open: true })}>Edit</Button>
                                                <UpdateCustomerInfoDialog open={drawerState.open && drawerState.action === 'editCustomerInfo'} apiHelper={apiHelper} order={orderInfo} onClose={handleDialogClose} />
                                            </> : <></>
                                        }}
                                    </UserConsumer>
                                </Box>
                                <Divider />
                                <Box padding={1}>

                                    <LabelLine title='Email:' value={orderInfo.user_info?.email ?? 'N/A'} />

                                    <LabelLine title='User Active:' value={orderInfo.tbl_users_tbl_order_useridTotbl_users.status ? 'Yes' : 'No'} />
                                    <Box display='flex'>
                                        <LabelLine title='Repeat Customer:' value={'Info unavailable for now'} />
                                        <Typography sx={{
                                            color: 'blue',
                                            textDecoration: 'underline',
                                            cursor: 'pointer'
                                        }}>{repeatCustomerState.checked ? repeatCustomerState.checking ? 'Checking...' : repeatCustomerState.result ?? 'Could not find!' : 'Check Now'}</Typography>
                                    </Box>
                                </Box>
                            </Card>
                        </Grid> */}
                        <Grid item sm={12} md={6} xl={4} width="100%" >
                            <Card variant='outlined'>
                                <Box display='flex'>
                                    <Typography sx={{ padding: 1, flex: 1 }} variant="h5">Order Process & Tracking</Typography>
                                    {
                                        orderInfo.tbl_order_room?.tracking_id && orderInfo.tbl_order_room?.courier_partners?.tracking_link ? <Tooltip title="Track order on courier provider's website">
                                            <Button href={`${orderInfo.tbl_order_room.courier_partners.tracking_link}${orderInfo.tbl_order_room.tracking_id}`} target="_blank">Track</Button>
                                        </Tooltip> : null
                                    }
                                </Box>
                                <Divider />
                                <Box padding={1} display='flex' alignItems='center'>
                                    <LabelLine title='Status: ' value={''} />
                                    <Chip label={orderStatus.title} color={orderStatus.color} />
                                </Box>
                                <Divider />
                                <Box padding={1}>
                                    <LabelLine title='Comment: ' value={orderInfo.tbl_order_room?.disposition_desc ?? "N/A"} />
                                    <LabelLine title='Courier Provider: ' value={`(${orderInfo.tbl_order_room?.courier_partners?.alias ?? "Not dispatched"}) - ${orderInfo.tbl_order_room?.tracking_id ?? "N/A"}`} />
                                    <LabelLine title='Dispatched At: ' value={orderInfo.tbl_order_room?.dispatched_at !== undefined && orderInfo.tbl_order_room?.dispatched_at !== null ? moment(orderInfo.tbl_order_room?.dispatched_at).format("DD/MM/YYYY") : "Not dispatched"} />
                                    <LabelLine title='Delivered At: ' value={orderInfo.tbl_order_room?.delivered_at !== undefined && orderInfo.tbl_order_room?.delivered_at !== null ? moment(orderInfo.tbl_order_room?.delivered_at).format("DD/MM/YYYY") : "Either not dispatched, or returned"} />
                                </Box>
                                <Divider />
                                <Box padding={1}>
                                    <Typography color={orderStatus.color}>{orderStatus.description}</Typography>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item sm={12} md={6} xl={4} width="100%">
                            <DataGrid
                                components={{
                                    Header: () => {
                                        return <Box>
                                            <Typography sx={{ padding: 1 }} variant='h6'>Products</Typography>
                                            <Divider />
                                        </Box>
                                    }
                                }}
                                rowHeight={70}
                                pageSize={10}
                                disableSelectionOnClick
                                rowsPerPageOptions={[10]}
                                getRowId={e => e.proid}
                                rows={orderInfo?.order_meta ?? []}
                                autoHeight
                                columns={productGridColumn}
                            />
                        </Grid>
                        <Grid item sm={12} md={6} xl={4} width="100%">
                            <Card variant='outlined'>
                                <Box display='flex' padding={1}>
                                    <Typography sx={{ flex: 1 }} variant="h5">Payment Information</Typography>
                                    <UserConsumer>
                                        {(data) => {
                                            return orderStatus.key === "pending" && orderEditable && data.permissions?.includes("v3_action_new_edit_payment") ? <>
                                                <Button onClick={_ => setDrawerState({ action: 'editPaymentInfo', open: true })}>Edit</Button>
                                                <UpdatePaymentInfoDialog open={drawerState.open && drawerState.action === 'editPaymentInfo'} apiHelper={apiHelper} order={orderInfo} onClose={handleDialogClose} />
                                            </> : <></>
                                        }}
                                    </UserConsumer>
                                </Box>
                                <Divider />
                                <Box padding={1}>
                                    <Box mb={1} display='flex' alignItems='center'>
                                        <LabelLine title='Method: ' value={''} />
                                        <Chip sx={{ textTransform: 'uppercase' }} color={orderInfo.payment_mode === 'Prepaid' ? 'success' : 'secondary'} label={orderInfo.payment_mode} />
                                    </Box>
                                    <Box mb={1} display='flex' alignItems='center'>
                                        <LabelLine title='Status: ' value={''} />
                                        <Chip sx={{ textTransform: 'uppercase' }} color={orderInfo.status === 'success' ? 'success' : orderInfo.status === 'failed' ? 'error' : orderInfo.status === 'pending' ? 'warning' : 'default'} label={orderInfo.status} />
                                    </Box>

                                </Box>
                            </Card>
                        </Grid>
                    </Grid>
                    <Divider sx={{ marginY: 3 }} />
                    {
                        orderStatus && orderEditable ? <UserConsumer>
                            {(data) => {
                                return <Box display='flex'>
                                    {
                                        ["pending", "hold"].includes(orderStatus.key) && haveRequiredPermission(data.permissions ?? [], ["v3_action_new_accept", "v3_action_new_hold"]) ? <>
                                            <Button sx={{ margin: 1 }} variant='contained' color='success' onClick={() => {
                                                setDrawerState({
                                                    open: true,
                                                    action: 'accept'
                                                })
                                            }} >Accept Order</Button>
                                            <AcceptOrderDialog open={drawerState.open && drawerState.action === 'accept'} apiHelper={apiHelper} order={orderInfo} onClose={handleDialogClose} />
                                        </> : <></>
                                    }
                                    {
                                        orderStatus.key === "verified" && haveRequiredPermission(data.permissions ?? [], ["v3_action_accepted_dispatch"]) ? <>
                                            <Button sx={{ margin: 1 }} variant='contained' color='success' onClick={() => {
                                                setDrawerState({
                                                    open: true,
                                                    action: 'dispatch'
                                                })
                                            }}>Dispatch Order</Button>
                                            <DispatchOrderDialog open={drawerState.open && drawerState.action === 'dispatch'} apiHelper={apiHelper} order={orderInfo} onClose={handleDialogClose} />
                                        </> : <></>
                                    }
                                    {
                                        orderStatus.key === "pending" && haveRequiredPermission(data.permissions ?? [], ["v3_action_new_hold"]) ? <>
                                            <Button sx={{ margin: 1 }} variant='contained' color='warning' onClick={() => {
                                                setDrawerState({
                                                    open: true,
                                                    action: 'hold'
                                                })
                                            }}>Hold Order</Button>
                                            <HoldOrderDialog open={drawerState.open && drawerState.action === 'hold'} apiHelper={apiHelper} order={orderInfo} onClose={handleDialogClose} />
                                        </> : <></>
                                    }
                                    {
                                        ["pending", "verified", "hold"].includes(orderStatus.key) && haveRequiredPermission(data.permissions ?? [], ["v3_action_new_cancel", "v3_action_accepted_cancel", "v3_action_hold_cancel"]) ? <>
                                            <Button sx={{ margin: 1 }} variant='contained' color='error' onClick={() => {
                                                setDrawerState({
                                                    open: true,
                                                    action: 'cancel'
                                                })
                                            }}>Cancel Order</Button>
                                            <CancelOrderDialog open={drawerState.open && drawerState.action === 'cancel'} apiHelper={apiHelper} order={orderInfo} onClose={handleDialogClose} />
                                        </> : <></>
                                    }
                                    {
                                        ["dispatched"].includes(orderStatus.key) && haveRequiredPermission(data.permissions ?? [], ["v3_action_dispatched_update_transit_status"]) ? <>
                                            <Button sx={{ margin: 1 }} variant='contained' color='info' onClick={() => {
                                                setDrawerState({
                                                    open: true,
                                                    action: 'transit_update'
                                                })
                                            }}>Update transit status</Button>
                                            <UpdateTransitStatusDialog open={drawerState.open && drawerState.action === 'transit_update'} apiHelper={apiHelper} order={orderInfo} onClose={handleDialogClose} />
                                        </> : <></>
                                    }
                                </Box>
                            }}
                        </UserConsumer> : <></>
                    }
                    {
                        !orderEditable ? <Typography color="error"><b>*Note: </b>This order can not be processed as it is over a month old!</Typography> : <></>
                    }
                    {
                        drawerState.open && drawerState.action === 'product' && typeof drawerState.data !== 'undefined' ? <UpdateProductDialog open={true} apiHelper={apiHelper} data={drawerState.data} order={orderInfo} onClose={handleDialogClose} /> : <></>
                    }
                </Box>
            }
        </Layout >
    )
}
