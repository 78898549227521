import { Avatar, Box, Card, CardContent, Grid, Icon, Typography } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { ArrowUpward } from '@mui/icons-material';
// import MoneyIcon from '@mui/icons-material/Money';

interface BudgetProps {
  title: string
  value: string
  icon?: string
  iconColor?: string
  grow?: 'up' | 'down'
  growText?: string
  growSubtitle?: string
}

export const Budget = (props: BudgetProps) => (
  <Card
    sx={{ height: '100%' }}
  >
    <CardContent>
      <Grid
        container
        spacing={3}
        sx={{ justifyContent: 'space-between' }}
      >
        <Grid item>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="overline"
          >
            {props.title}
          </Typography>
          <Typography
            color="textPrimary"
            variant="h4"
          >
            {props.value}
          </Typography>
        </Grid>
        {
          props.icon ? <Grid item>
            <Avatar
              sx={{
                backgroundColor: props.iconColor ?? "#FFF",
                height: 56,
                width: 56
              }}
            >
              <Icon>{props.icon}</Icon>
            </Avatar>
          </Grid> : <></>
        }
      </Grid>
      {
        props.grow && props.growText ? <Box
          sx={{
            pt: 2,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {
            props.grow === 'up' ? <ArrowUpward color='success' /> : <ArrowDownwardIcon color="error" />
          }
          <Typography
            color={props.grow === 'up' ? "success" : "error"}
            sx={{
              mr: 1
            }}
            variant="body2"
          >
            {props.growText}
          </Typography>
          <Typography
            color="textSecondary"
            variant="caption"
          >
            {props.growSubtitle}
          </Typography>
        </Box> : <></>
      }
    </CardContent>
  </Card>
);
