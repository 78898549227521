import { Alert, AlertTitle, Box, Button, Dialog, DialogContent, Divider, Grid, LinearProgress, TextField, Typography } from '@mui/material'
import { useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import Order from '../../interface/order'
import Api from '../../lib/api'
import { useQuery } from '../../lib/util'

interface HoldOrderDialogProps {
    open: boolean
    order: Order
    apiHelper: Api
    onClose?: (e: any) => any
}


type Inputs = {
    holdMessage: string,
};

export default function HoldOrderDialog(props: HoldOrderDialogProps) {
    const navigator = useNavigate()
    const query = useQuery()
    const callbackUrl = useMemo(() => query.get('callback'), [query])
    const { register, handleSubmit, formState: { errors }, reset } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        
        setDialogState({
            error: "",
            processing: true
        })
        const response = await props.apiHelper.updateOrder(props.order.orderid, {
            status: 'hold',
            disposition_desc: data.holdMessage
        })
        if (response.ok) {
            navigator(callbackUrl ? decodeURIComponent(callbackUrl) : "/", {
                replace: true
            })
        } else {
            setDialogState({
                error: response.message!,
                processing: false
            })
        }
    };
    const [dialogState, setDialogState] = useState({
        processing: false,
        error: ''
    })

    const handleClose = () => {
        reset()
        setDialogState({
            processing: false,
            error: ''
        })
        if (typeof props.onClose !== 'undefined') {
            props.onClose('ok')
        }
    }

    return <Dialog open={props.open} fullWidth>
        <DialogContent>
            <Typography variant='h6'>Hold Order</Typography>
            <Typography>{`Hold order: ${props.order.orderid}`}</Typography>
        </DialogContent>
        <Divider />
        {
            dialogState.processing ? <LinearProgress /> : <></>
        }
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
                {
                    dialogState.error !== '' ? <Box mb={3}>
                        <Alert severity="error">
                            <AlertTitle>Error</AlertTitle>
                            {dialogState.error}
                        </Alert>
                    </Box> : <></>
                }
                <TextField {...register("holdMessage", {
                    required: "Reason for hold is required."
                })} sx={{ marginBottom: 2 }} label="Reason for hold" fullWidth={true} autoFocus={true} error={errors.holdMessage?.message !== undefined} helperText={errors.holdMessage?.message} />
            </DialogContent>
            <Divider />
            <DialogContent>
                <Grid width="100%" container>
                    <Grid sx={{ paddingRight: .5 }} justifyContent='center' item sm={12} md={6}>
                        <Button disabled={dialogState.processing} fullWidth variant='contained' color='error' onClick={handleClose}>Cancel</Button>
                    </Grid>
                    <Grid sx={{ paddingLeft: .5 }} justifyContent='center' item sm={12} md={6}>
                        <Button disabled={dialogState.processing} type="submit" fullWidth variant='contained' color='warning'>Hold Order</Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </form>
    </Dialog>
}
