import { AdminPanelSettings, Menu as MenuIcon } from "@mui/icons-material";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { AppBar, Avatar, Button, CssBaseline, Divider, Drawer, Icon, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Switch, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React, { useMemo } from 'react';
import Helmet from "react-helmet";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ARAdminMenuItem from "../interface/ar_admin_menu_item";
import ARMenu from '../interface/ar_menu_item';
import { DarkModeConsumer } from "../lib/dark_mode_context";
import { UserConsumer } from "../lib/user_context";
import { getAdminMenuItems, getMenuItems } from "../lib/util";

interface LayoutProps {
    title?: string
    children?: any | any[]
    window?: () => Window,
    padding?: number
}

const drawerWidth = 240;

export default function Layout(props: LayoutProps) {
    const navigator = useNavigate()
    const location = useLocation()
    const { window } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleELClose = () => {
        setAnchorEl(null);
    };

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.up("sm"))
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const menuItems = useMemo(() => getMenuItems(), [])
    const adminMenuItems = useMemo(() => getAdminMenuItems()[1], [])
    // console.log(menuItems)

    const handleLogout = () => {
        localStorage.removeItem("order_room_v4_token")
        navigator("/login")
    }

    // console.log(location.pathname)
    // console.log(location)

    // {
    //         title: "Admin",
    //         icon: 'admin_panel_settings',
    //         href: "/admin",
    //         permissionString: "",
    //     }

    const drawer = (
        <div>
            <Toolbar />
            <Divider />
            <UserConsumer>
                {(data) => {
                    return <List>
                        {
                            (menuItems as ARMenu[]).map((e, index) => {
                                return (e.permissionString === "" || data.permissions?.includes(e.permissionString)) && <ListItem key={index} disablePadding>
                                    <ListItemButton
                                        component={Link}
                                        to={e.href}
                                        selected={`${location.pathname}` === e.href}>
                                        <ListItemIcon>
                                            <Icon>{e.icon}</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary={e.title} />
                                    </ListItemButton>
                                </ListItem>
                            })
                        }
                        {
                            data.role === "admin" ? <ListItem disablePadding>
                                <ListItemButton
                                    component={Link}
                                    to="/admin"
                                    selected={adminMenuItems.map((e: ARAdminMenuItem) => e.pathname).includes(`${location.pathname}`)}>
                                    <ListItemIcon>
                                        <AdminPanelSettings />
                                    </ListItemIcon>
                                    <ListItemText primary="Admin" />
                                </ListItemButton>
                            </ListItem> : <></>
                        }
                        <ListItem>
                            <ListItemIcon>
                                <DarkModeIcon />
                            </ListItemIcon>
                            <ListItemText primary="Dark Mode" />
                            <DarkModeConsumer>
                                {(value) => {
                                    return <Switch
                                        edge="end"
                                        onChange={e => value.setEnabled(e.target.checked)}
                                        checked={value.enabled}
                                    />
                                }}
                            </DarkModeConsumer>
                        </ListItem>
                    </List>
                }}
            </UserConsumer>
        </div >
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <>
            <Helmet>
                <title>{props.title ? `${props.title} - Order Room` : 'Order Room'}</title>
            </Helmet>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    sx={{
                        zIndex: (_) => isMobile ? theme.zIndex.drawer + 1 : theme.zIndex.drawer
                    }}
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Avatar alt="AR Ayurveda" src="/assets/logo.png" sx={{
                            display: { sx: 'none', sm: 'none', md: 'block' },
                            backgroundColor: 'white',
                            marginRight: 2
                        }} />
                        <Typography variant="h6" noWrap component="div">
                            Order Room
                            <Typography color='whitesmoke' variant='subtitle2'>AR Ayurveda</Typography>
                        </Typography>
                        <Box flex={1} textAlign='end'>
                            <IconButton></IconButton>
                            <Button
                                variant='contained'
                                color='secondary'
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleMenuClick}
                            >
                                <UserConsumer>
                                    {(value) => value.name ?? "Anonymous"}
                                </UserConsumer>
                            </Button>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleELClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={_ => {
                                    handleELClose()
                                    handleLogout()
                                }}>Logout</MenuItem>
                            </Menu>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                >
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        {drawer}
                    </Drawer>
                    <Drawer
                        variant="permanent"
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                        open
                    >
                        {drawer}
                    </Drawer>
                </Box>
                <Box
                    component="main"
                    sx={{ flexGrow: 1, p: props.padding ?? 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
                >
                    <Toolbar />
                    {
                        props.children
                    }
                </Box>
            </Box>
        </>
    )
}
