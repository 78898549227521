import { Typography } from "@mui/material";
import AdminLayout from "../../layouts/AdminLayout";

export default function AdminLandingPage() {
  return (
    <AdminLayout title="Admin Home">
      <Typography>*Choose a tab from above to see content here.</Typography>
    </AdminLayout>
  )
}
