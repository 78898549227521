import { Alert, AlertTitle, Box, Button, Dialog, DialogContent, Divider, Grid, LinearProgress, TextField, Typography } from '@mui/material'
import { useState, useCallback } from 'react'
import Order from '../../../interface/order'
import Api from '../../../lib/api'
import { isInt } from '../../../lib/util'

interface UpdateProductDialogProps {
    open: boolean
    order: Order
    data: any
    apiHelper: Api
    onClose?: (e: any) => any
}

export default function UpdateProductDialog(props: UpdateProductDialogProps) {

    const [dialogState, setDialogState] = useState({
        processing: false,
        error: '',
        input: props.data.paid_amount
    })

    const updatePrice = useCallback(
        async () => {
            if (dialogState.input === '') {
                setDialogState(old => ({ ...old, error: 'Enter the price!' }))
                return
            }
            setDialogState(old => ({ ...old, error: '', processing: true }))
            const response = await props.apiHelper.updateOrderMeta(props.data.id, 'productMeta', {
                "paid_amount": parseInt(dialogState.input)
            })
            if (response.ok) {
                window.location.reload()
            } else {
                setDialogState(old => ({
                    ...old,
                    error: response.message!,
                    processing: false
                }))
            }
        },
        [dialogState.input, props.apiHelper, props.data.id],
    )


    const handleClose = () => {
        // reset()
        setDialogState(old => ({
            ...old,
            processing: false,
            error: ''
        }))
        if (typeof props.onClose !== 'undefined') {
            props.onClose('ok')
        }
    }

    return (
        <Dialog open={props.open}>
            <DialogContent>
                <Typography variant='h6'>Update product information</Typography>
                <Typography>{`#${props.order.orderid}`}</Typography>
            </DialogContent>
            <Divider />
            {
                dialogState.processing ? <LinearProgress /> : <></>
            }
            <DialogContent>
                {
                    dialogState.error !== '' ? <Box mb={3}>
                        <Alert severity="error">
                            <AlertTitle>Error</AlertTitle>
                            {dialogState.error}
                        </Alert>
                    </Box> : <></>
                }
                <Box display='flex'>
                    <img src={props.data.tbl_product.tbl_products_gallary.path} alt="" style={{
                        height: 100,
                        width: 100,
                        borderRadius: 10
                    }} />
                    <Box pl={2}>
                        <Typography variant='h6' sx={{ mb: 1 }} >{props.data.tbl_product.name.split("-")[0]}</Typography>
                        <TextField label='Price' value={dialogState.input ?? ""} onChange={e => {
                            if (e.target.value === '' || isInt(e.target.value)) {
                                setDialogState(old => ({ ...old, input: e.target.value }))
                            }
                        }} />
                    </Box>
                </Box>
            </DialogContent>
            <Divider />
            <DialogContent>
                <Grid width="100%" container>
                    <Grid sx={{ paddingRight: .5 }} justifyContent='center' item sm={12} md={6}>
                        <Button disabled={dialogState.processing} fullWidth variant='contained' color='error' onClick={handleClose}>Cancel</Button>
                    </Grid>
                    <Grid sx={{ paddingLeft: .5 }} justifyContent='center' item sm={12} md={6}>
                        <Button type="submit" fullWidth variant='contained' color='success' onClick={updatePrice}>Update</Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
