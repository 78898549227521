import { Alert, AlertTitle, Box, Button, Dialog, DialogContent, Divider, Grid, LinearProgress, TextField, Typography } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import Order from '../../../interface/order'
import PostOffice from '../../../interface/post_office'
import Api from '../../../lib/api'

interface UpdateShippingInfoDialogProps {
    open: boolean
    order: Order
    apiHelper: Api
    onClose?: (e: any) => any
}

type Inputs = {
    fullname: string,
    phone: string,
    address_line_1: string,
    address_line_2?: string,
    city: string,
    district?: string,
    state?: string,
    pincode: number,
};

export default function UpdateShippingInfoDialog(props: UpdateShippingInfoDialogProps) {
    const { register, handleSubmit, formState: { errors }, clearErrors, reset, watch, setValue, setError } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        // console.log(data)
        setDialogState({
            error: "",
            processing: true
        })
        const response = await props.apiHelper.updateOrderMeta(props.order.orderid, 'userMeta', { ...data, phone: parseInt(data.phone), pincode: parseInt(data.pincode.toString()), address_line_2: data.address_line_2 && data.address_line_2.trim() !== "" ? data.address_line_2 : null })
        if (response.ok) {
            window.location.reload()
        } else {
            setDialogState({
                error: response.message!,
                processing: false
            })
        }
    };
    const [dialogState, setDialogState] = useState<{
        processing: boolean,
        error: string,
        pincodeData?: PostOffice[]
    }>({
        processing: false,
        error: '',
        pincodeData: undefined
    })

    const handleClose = () => {
        reset()
        setDialogState({
            processing: false,
            error: ''
        })
        if (typeof props.onClose !== 'undefined') {
            props.onClose('ok')
        }
    }

    const fetchPincode = useCallback(
        async (pincode: number) => {
            setDialogState(old => ({ ...old, processing: true }))

            const response = await props.apiHelper.findPincodeDetails(pincode.toString())
            // console.log(response)
            if (response.ok) {
                clearErrors("state")
                clearErrors("district")
                setValue("state", response.data![0].State ?? "")
                setValue("district", response.data![0].District ?? "")

                setDialogState(old => ({ ...old, processing: false }))
            } else {
                setError("pincode", {
                    message: response.message ?? "Something went wrong while checking pincode."
                })
                setDialogState(old => ({ ...old, processing: false }))
            }
        },
        [props.apiHelper, clearErrors, setValue, setError]
    )

    const pincodeVal = watch('pincode')
    useEffect(() => {
        if ((pincodeVal?.toString()?.length ?? 0) === 6) {
            fetchPincode(pincodeVal)
        }
    }, [pincodeVal, fetchPincode])


    useEffect(() => {
        if (typeof props.order.user_info?.pincode !== 'undefined' && props.open) {
            fetchPincode(props.order.user_info!.pincode!)
        }
    }, [props.order.user_info, fetchPincode, props.open])



    return <Dialog scroll='paper' open={props.open} fullWidth>
        <DialogContent>
            <Typography variant='h6'>Update customer information</Typography>
            <Typography>{`#${props.order.orderid}`}</Typography>
        </DialogContent>
        <Divider />
        {
            dialogState.processing ? <LinearProgress /> : <></>
        }
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
                {
                    dialogState.error !== '' ? <Box mb={3}>
                        <Alert severity="error">
                            <AlertTitle>Error</AlertTitle>
                            {dialogState.error}
                        </Alert>
                    </Box> : <></>
                }
                <Grid container spacing={1.5}>
                    <Grid item sm={6}>
                        <TextField {...register("fullname", {
                            required: "Full name of customer is required."
                        })} defaultValue={props.order.user_info?.fullname} sx={{ marginBottom: 2 }} label="Full name" fullWidth={true} autoFocus={true} error={errors.fullname?.message !== undefined} helperText={errors.fullname?.message} />
                    </Grid>
                    <Grid item sm={6}>
                        <TextField {...register("phone", {
                            required: "Phone number of customer is required.",
                            pattern: {
                                value: /^\d+$/,
                                message: "A valid phone number is required."
                            },
                            minLength: {
                                value: 10,
                                message: 'A phone number must be at least 10 digits long.'
                            },
                            maxLength: {
                                value: 13,
                                message: "A phone number must be smaller then 13 digits."
                            }
                        })} defaultValue={props.order.user_info?.phone} sx={{ marginBottom: 2 }} label="Phone Number" fullWidth={true} error={errors.phone?.message !== undefined} helperText={errors.phone?.message} />
                    </Grid>
                </Grid>
                <TextField {...register("address_line_1", {
                    required: "Address line 1 is required."
                })} defaultValue={props.order.user_info?.address_line_1} sx={{ marginBottom: 2 }} label="Address Line 1" fullWidth={true} autoFocus={true} error={errors.address_line_1?.message !== undefined} helperText={errors.address_line_1?.message} />
                <TextField {...register("address_line_2")} defaultValue={props.order.user_info?.address_line_2} sx={{ marginBottom: 2 }} label="Address Line 2 (Optional)" fullWidth={true} />
                <Grid container rowSpacing={0} columnSpacing={1.5}>
                    <Grid item sm={6}>
                        <TextField {...register("city", {
                            required: "City is required."
                        })} defaultValue={props.order.user_info?.city} sx={{ marginBottom: 2 }} label="City" fullWidth={true} error={errors.city?.message !== undefined} helperText={errors.city?.message} />
                    </Grid>
                    <Grid item sm={6}>
                        <TextField {...register("pincode", {
                            required: "Pincode is required.",
                            pattern: {
                                value: /^\d+$/,
                                message: 'Pincode must be numeric only'
                            },
                            maxLength: {
                                value: 6,
                                message: "Pincode length must be not more or less then 6"
                            },
                            minLength: {
                                value: 6,
                                message: "Pincode length must be not more or less then 6"
                            }
                        })} disabled={dialogState.processing} defaultValue={props.order.user_info?.pincode} sx={{ marginBottom: 2 }} label="Pincode" fullWidth={true} error={errors.pincode?.message !== undefined} helperText={errors.pincode?.message} />
                    </Grid>
                    <Grid item sm={6}>
                        <TextField disabled={true} {...register("district", {
                            required: "District is required."
                        })} defaultValue={props.order.user_info?.district} sx={{ marginBottom: 2 }} label="District" fullWidth={true} error={errors.district?.message !== undefined} helperText={errors.district ? errors.district?.message : 'Based on pincode'} />
                    </Grid>
                    <Grid item sm={6}>
                        <TextField disabled={true} {...register("state", {
                            required: "State is required."
                        })} defaultValue={props.order.user_info?.state} sx={{ marginBottom: 2 }} label="State" fullWidth={true} error={errors.state?.message !== undefined} helperText={errors.state ? errors.state?.message : 'Based on pincode'} />
                    </Grid>
                </Grid>

            </DialogContent>
            <Divider />
            <DialogContent>
                <Grid width="100%" container>
                    <Grid sx={{ paddingRight: .5 }} justifyContent='center' item sm={12} md={6}>
                        <Button disabled={dialogState.processing} fullWidth variant='contained' color='error' onClick={handleClose}>Cancel</Button>
                    </Grid>
                    <Grid sx={{ paddingLeft: .5 }} justifyContent='center' item sm={12} md={6}>
                        <Button disabled={dialogState.processing || Object.entries(errors).length > 0} type="submit" fullWidth variant='contained' color='success'>Update</Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </form>
    </Dialog>
}
