import React from 'react'
interface UserContextData {
    name?: string,
    role?: string,
    permissions?: string[]
}

const UserContext = React.createContext<UserContextData>({
    name: "Anonymous",
    permissions: []
})

export const UserProvider = UserContext.Provider
export const UserConsumer = UserContext.Consumer
export default UserContext