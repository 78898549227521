import { Alert, AlertTitle, Box, Button, Dialog, DialogContent, Divider, FormControl, FormHelperText, Grid, InputLabel, LinearProgress, MenuItem, Select, TextField, Typography } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import moment from 'moment'
import { useCallback, useMemo, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import Order from '../../interface/order'
import Api from '../../lib/api'
import { useQuery } from '../../lib/util'

interface UpdateTransitStatusDialogProps {
    open: boolean
    order: Order
    apiHelper: Api
    onClose?: (e: any) => any
}


type Inputs = {
    transitStatus: string,
    deliveredAt: Date,
    reasonForReturn: string
};


export default function UpdateTransitStatusDialog(props: UpdateTransitStatusDialogProps) {
    const navigator = useNavigate()
    const query = useQuery()
    const callbackUrl = useMemo(() => query.get('callback'), [query])
    const { handleSubmit, formState: { errors }, reset, control, watch } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        setDialogState({
            error: "",
            processing: true
        })
        const dd: { [key: string]: any } = {
            status: 'dispatch',
            location: data.transitStatus
        }
        if (data.transitStatus === 'delivered') {
            dd['delivered_at'] = moment(data.deliveredAt).format("YYYY-MM-DD HH:mm:ss")
        }
        if (data.transitStatus === 'returned') {
            dd['disposition_desc'] = data.reasonForReturn
        }
        const response = await props.apiHelper.updateOrder(props.order.orderid, dd)
        if (response.ok) {
            navigator(callbackUrl ? decodeURIComponent(callbackUrl) : "/", {
                replace: true
            })
        } else {
            setDialogState({
                error: response.message,
                processing: false
            })
        }
    };
    const [dialogState, setDialogState] = useState<{
        processing: boolean
        error?: string
    }>({
        processing: false,
        error: '',
    })

    const handleClose = useCallback(() => {
        reset()
        setDialogState({
            processing: false,
            error: '',
        })
        if (typeof props.onClose !== 'undefined') {
            props.onClose('ok')
        }
    }, [props, reset])






    return <Dialog open={props.open} fullWidth>
        <DialogContent>
            <Typography variant='h6'>Update transit status</Typography>
            <Typography>{`Update transit status: ${props.order.orderid}`}</Typography>
        </DialogContent>
        <Divider />
        {
            dialogState.processing ? <LinearProgress /> : <></>
        }
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
                {
                    dialogState.error !== '' ? <Box mb={3}>
                        <Alert severity="error">
                            <AlertTitle>Error</AlertTitle>
                            {dialogState.error}
                        </Alert>
                    </Box> : <></>
                }
                <Controller
                    name="transitStatus"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Select a transit status!" }}
                    render={({ field }) => <FormControl fullWidth sx={{ mb: 1.5 }}>
                        <InputLabel id="transit_status_label_id">Transit Status</InputLabel>
                        <Select
                            {...field}
                            error={errors.transitStatus?.message !== undefined}
                            labelId="transit_status_label_id"
                            label="Courier Partner"
                        >
                            <MenuItem value='delivered'>Delivered</MenuItem>
                            <MenuItem value='returned'>Returned</MenuItem>
                        </Select>
                        <FormHelperText variant='filled' error={errors.transitStatus?.message !== undefined} >{errors.transitStatus?.message}</FormHelperText>
                    </FormControl>} />
                {
                    watch("transitStatus") === 'delivered' ? <Controller
                        name="deliveredAt"
                        control={control}
                        defaultValue={new Date()}
                        rules={{ required: "Delivery date is required!" }}
                        render={({ field }) => <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                {...field}
                                label="Delivered At"
                                inputFormat='dd/MM/yyyy'
                                renderInput={(params) => <TextField {...params} error={typeof errors.deliveredAt?.message !== 'undefined'} helperText={errors.deliveredAt?.message} fullWidth />}
                            />
                        </LocalizationProvider>} /> : <></>
                }
                {
                    watch("transitStatus") === 'returned' ? <Controller defaultValue='' control={control} name='reasonForReturn' rules={{ required: "Reason for return is required!" }} render={({ field }) => <TextField {...field} label="Reason for return" error={typeof errors.reasonForReturn?.message !== 'undefined'} helperText={errors.reasonForReturn?.message} fullWidth />} /> : <></>
                }
            </DialogContent>
            <Divider />
            <DialogContent>
                <Grid width="100%" container>
                    <Grid sx={{ paddingRight: .5 }} justifyContent='center' item sm={12} md={6}>
                        <Button disabled={dialogState.processing} fullWidth variant='contained' color='error' onClick={handleClose}>Cancel</Button>
                    </Grid>
                    <Grid sx={{ paddingLeft: .5 }} justifyContent='center' item sm={12} md={6}>
                        <Button disabled={dialogState.processing} type="submit" fullWidth variant='contained' color='info'>Update Status</Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </form>
    </Dialog>
}
