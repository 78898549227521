import { Grid, Typography, useTheme } from '@mui/material'
import Layout from '../layouts/MainLayout'

export default function LandingPage() {
    const theme = useTheme()

    return (
        <Layout title='Welcome'>
            <Grid container justifyContent='center' alignItems='center' sx={{
                height: `calc(90vh - ${theme.mixins.toolbar.minHeight}px)`
            }}>
                <Grid item>
                    <Typography>Choose from menu to see content here.</Typography>
                </Grid>
            </Grid>
        </Layout>
    )
}
