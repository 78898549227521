import { Box, Chip, Divider, Grid, Icon, LinearProgress, Pagination, PaginationItem, Tooltip, Typography, useTheme } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import Layout from '../layouts/MainLayout';
import ARMenu from '../interface/ar_menu_item';
import Order from '../interface/order';
import Api from '../lib/api';
import { UserConsumer } from '../lib/user_context';
import { getMenuItems, orderSourceCodeToString, orderStatusToIcon, useQuery } from '../lib/util';
import NoMatch from './NoMatch';

export default function Types() {
    const theme = useTheme()
    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const location = useLocation()
    const query = useQuery()
    const [processing, setProcessing] = useState(true)
    const currentType = useMemo(() => {
        return getMenuItems(location.pathname)
    }, [location.pathname]) as ARMenu
    const apiHelper = useMemo(() => new Api(), [])
    const [dataRows, setDataRows] = useState<Order[]>([])
    const [totalPages, setTotalPages] = useState<number>(0)
    const currentPage = useMemo(() => {
        const p = query.get("page")
        if (p !== null) {
            return parseInt(p)
        }
        return 1
    }, [query])

    // const makeUrl = (orderId) => {
    //     // let callBackUrl = 
    //     // return `/u/${orderId}?callback=${}`
    // }

    const columns: GridColDef[] = useMemo(() => {
        const col = [
            {
                field: 'created_at', filterable: false, sortable: false, description: "Date on which customer placed order", headerName: 'Order Date', width: 200, renderCell: (cell: any) => {
                    console.log(cell)

                    return <Box>
                        <Typography variant='subtitle2' noWrap>{moment(cell.value).format('DD-MM-YYYY')}</Typography>
                        <Typography variant='caption' noWrap>{moment(cell.value).fromNow()} at {moment(cell.value).format('hh:mm A')}</Typography>
                        {/* <Typography variant='caption' noWrap>{moment(cell.value).fromNow()} at {moment(cell.value).format('hh:mm A')}</Typography><br /> */}
                        {/* <Typography variant='caption' noWrap>{moment(cell.value).add("+5:30", "hour").fromNow()} at {moment(cell.value).format('HH:mm A')} </Typography> */}
                    </Box>
                }
            },
            {
                field: 'orderid', filterable: false, sortable: false, description: "Customer name and order id", headerName: 'Order Info', width: 200, renderCell: (cell: any) => {
                    // console.log(location.pathname, location.search)
                    return <Box>
                        <Grid container columnSpacing={1}>
                            <Grid item>
                                <Typography component={Link} to={`/u/${cell.value}?callback=${encodeURIComponent(`${location.pathname}${location.search}`)}`} sx={{
                                    textDecoration: 'underline',
                                    fontWeight: 'bold',
                                    color: theme.palette.error.main
                                }} variant='subtitle2' noWrap>{cell.value}</Typography>
                                <Typography variant='body2' noWrap>{cell.row.user_info.fullname}</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                }
            },
            {
                field: 'status', filterable: false, sortable: false, description: "Status of payment", headerName: 'Payment Status', width: 160, renderCell: (cell: any) => {
                    return <Chip color={cell.value === 'success' ? 'success' : cell.value === 'failed' ? 'error' : cell.value === 'pending' ? 'warning' : 'default'} sx={{
                        textTransform: 'capitalize'
                    }} label={cell.value} />
                }
            },
            {
                field: 'order_source', filterable: false, sortable: false, description: "Where did the order came from?", headerName: 'Order Source', width: 160, renderCell: (cell: any) => {
                    const d = orderSourceCodeToString(cell.value)
                    return <Chip icon={<Icon>{orderStatusToIcon(d)}</Icon>} color={cell.value === 'ARA-AD' ? 'success' : cell.value === 'ARA_WEB' ? 'info' : cell.value === 'ARA-IOS' ? 'warning' : 'default'} label={d} />
                }
            },
            {
                field: 'order_items', filterable: false, sortable: false, description: "Which products did customer buy?", headerName: 'Products', width: 210, renderCell: (cell: any) => {
                    const d = `${cell.row.order_meta[0].tbl_product.name.split("-")[0]}${cell.row.order_meta.length > 1 ? ` and ${cell.row.order_meta.length - 1} others` : ''}`
                    return <Tooltip title={d}>
                        <Typography variant="body2" noWrap >{d}</Typography >
                    </Tooltip>
                }
            },
            {
                field: 'order_amount', filterable: false, sortable: false, description: "Total to be paid by the customer", headerName: 'Order Total', width: 160, renderCell: (cell: any) => {
                    return <Chip color={cell.row.payment_mode === 'online' ? 'primary' : cell.row.payment_mode === 'cod' ? 'secondary' : 'default'} sx={{
                        textTransform: 'capitalize'
                    }} label={`₹${cell.row.order_meta.map((e: any) => e.paid_amount).reduce((a: number, b: number) => a + b, 0)} - ${cell.row.payment_mode}`} />
                }
            },
            {
                field: 'phone', filterable: false, sortable: false, description: "Contact number of customer", headerName: 'Contact', width: 160, renderCell: (cell: any) => {
                    return <Typography variant="body2" noWrap>{cell.row.user_info?.phone ?? 'N/A'}</Typography>
                }
            },
            {
                field: 'location', filterable: false, sortable: false, description: "State in which products have to be delivered", headerName: 'Destination', width: 160, renderCell: (cell: any) => {
                    return <Box>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }} noWrap>{cell.row.user_info?.state ?? 'N/A'}</Typography>
                        <Typography variant="caption" noWrap>Pin: {cell.row.user_info?.pincode ?? 'N/A'}</Typography>
                    </Box>
                }
            },

            {
                field: 'phonepay_payment_id', filterable: false, sortable: false, description: "Phonepe PaymentId", headerName: 'Phonepe PaymentId', width: 160, renderCell: (cell: any) => {


                    return <Box>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }} noWrap>{cell.value ?? 'N/A'}</Typography>
                    </Box>
                }
            },
            {
                field: 'phonepay_merchant_id', filterable: false, sortable: false, description: "Phonepe MerchantID", headerName: 'Phonepe MerchantID', width: 160, renderCell: (cell: any) => {


                    return <Box>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }} noWrap>{cell.value ?? 'N/A'}</Typography>
                    </Box>
                }
            },
        ]
        // if (currentType.status !== 'new') {
        //     col.splice(1, 0, {
        //         field: 'last_updated', filterable: false, sortable: false, description: "When was the last time this order was processed by us", headerName: 'Last Processed', width: 200, renderCell: (cell: any) => {

        //             return typeof cell.row.tbl_order_room?.updated_at !== 'undefined' ? <Box>
        //                 <Typography variant='subtitle2' noWrap>{moment(cell.row.tbl_order_room.updated_at).local().format('DD-MM-YYYY')}</Typography>
        //                 <Typography variant='caption' noWrap>{moment(cell.row.tbl_order_room.updated_at).local().fromNow()} at {moment(cell.row.tbl_order_room.updated_at).format('hh:mm A')}</Typography>
        //             </Box> : <Typography variant="body2">N/A</Typography>

        //         }
        //     })
        // }
        if (['accepted', 'hold', 'cancelled', 'dispatched'].includes(currentType?.status ?? "")) {
            col.splice(2, 0, {
                field: 'crm_id', filterable: false, sortable: false, description: "CRM Id of the order", headerName: 'CRM Id', width: 160, renderCell: (cell: any) => {
                    return <Typography variant="body2" noWrap>{cell.row.tbl_order_room?.crmid ?? 'N/A'}</Typography>
                }
            })
        }
        if (['cancelled', 'dispatched', 'hold'].includes(currentType?.status ?? "")) {
            col.splice(3, 0, {
                field: 'message', filterable: false, sortable: false, description: "Remark for this order", headerName: 'Remark', width: 160, renderCell: (cell: any) => {
                    return <Tooltip title={cell.row.tbl_order_room?.disposition_desc ?? 'N/A'}>
                        <Typography variant="body2" noWrap>{cell.row.tbl_order_room?.disposition_desc ?? 'N/A'}</Typography>
                    </Tooltip>
                }
            })
        }
        if (currentType?.status === 'dispatched' && currentType.dLoc === "delivered") {
            col.splice(1, 0, {
                field: 'delivered_at', filterable: false, sortable: false, description: "When was order delivered?", headerName: 'Deliver Date', width: 170, renderCell: (cell: any) => {
                    return <Box>
                        <Typography variant='subtitle2' noWrap>{cell.row.tbl_order_room?.delivered_at ? moment(cell.row.tbl_order_room?.delivered_at).format('DD-MM-YYYY') : "N/A"}</Typography>
                    </Box>
                }
            })
        }
        if (['dispatched', 'delivered', 'returned'].includes(currentType?.status ?? "")) {
            col.splice(1, 0, {
                field: 'dispatch_date', filterable: false, sortable: false, description: "When did order dispatch from source?", headerName: 'Dispatch Date', width: 170, renderCell: (cell: any) => {
                    return <Box>
                        <Typography variant='subtitle2' noWrap>{moment(cell.row.tbl_order_room?.dispatched_at).format('DD-MM-YYYY')}</Typography>
                    </Box>
                }
            })
            col.splice(3, 0, {
                field: 'courier_partner', filterable: false, sortable: false, description: "Which courier partner is going to deliver this order?", headerName: 'Courier Partner', width: 160, renderCell: (cell: any) => {
                    return <Tooltip title={cell.row.tbl_order_room?.courier_partners?.alias ?? 'N/A'}>
                        <Typography variant="body2" noWrap>{cell.row.tbl_order_room?.courier_partners?.alias ?? 'N/A'}</Typography>
                    </Tooltip>
                }
            })
            col.splice(4, 0, {
                field: 'tracking_id', filterable: false, sortable: false, description: "Tracking id to track this order", headerName: 'Tracking Id', width: 160, renderCell: (cell: any) => {
                    return <Tooltip title={cell.row.tbl_order_room.courier_partners.tracking_link ? "Click to track" : cell.row.tbl_order_room?.tracking_id ?? 'Not assigned'}>
                        {
                            cell.row.tbl_order_room?.courier_partners?.tracking_link ? <Typography style={{ fontWeight: 'bold' }} component={'a'} href={
                                `${cell.row.tbl_order_room?.courier_partners?.tracking_link}${cell.row.tbl_order_room.tracking_id}`
                            } color='error.main' target="_blank" variant="body2" noWrap>
                                {cell.row.tbl_order_room?.tracking_id ?? 'Not assigned'}
                            </Typography> : <Typography variant="body2" noWrap>{cell.row.tbl_order_room?.tracking_id ?? 'Not assigned'}</Typography>
                        }
                    </Tooltip >
                }
            })
            // col.splice(4, 0, {
            //     field: 'tracking_id', filterable: false, sortable: false, description: "Tracking id to track this order", headerName: 'Tracking Id', width: 160, renderCell: (cell: any) => {
            //         return <Tooltip title={cell.row.tbl_order_room?.tracking_id ?? 'N/A'}>
            //             <Typography variant="body2" noWrap>{cell.row.tbl_order_room?.tracking_id ?? 'N/A'}</Typography>
            //         </Tooltip>
            //     }
            // })
        }
        return col
    }, [currentType, location, theme])

    const getData = useCallback(async () => {
        setProcessing(true)
        const response = await apiHelper.fetchOrdersByStatus({
            type: currentType.status as any, limit: 10, page: currentPage, dLoc: currentType.dLoc, filter: {
                startDate: moment().subtract(28, 'days').startOf("day").toDate(),
                endDate: moment().endOf("day").toDate()
            }
        })
        if (response.ok) {
            setProcessing(false)
            setDataRows(response.data?.data ?? [])
            setTotalPages(response.data?.total ?? 0)
        } else {
            setProcessing(false)
            showSnackbar(response.message, {
                variant: 'error'
            })
        }
    }, [currentType, apiHelper, currentPage, showSnackbar])

    useEffect(() => {
        if (currentType) {
            getData()
        }
    }, [getData, currentType])


    return (
        <UserConsumer>
            {(data) => {
                return data.permissions?.includes(currentType?.permissionString ?? "") ? < Layout title={currentType?.title ?? ""} >
                    <Typography sx={{
                        marginBottom: 1
                    }} color='red' variant='subtitle2'>*Note: Click on any order id to view details.</Typography>

                    <DataGrid
                        rowsPerPageOptions={[10]}
                        rowHeight={70}
                        columns={columns}
                        loading={processing}
                        rows={dataRows}
                        getRowId={e => e.orderid}
                        paginationMode='server'
                        page={currentPage}
                        pageSize={10}
                        pagination
                        componentsProps={{
                            footer: currentType
                        }}
                        components={{
                            LoadingOverlay: () => {
                                return <LinearProgress />
                            },
                            Footer: (footerProps: ARMenu) => {
                                return <Box>
                                    <Divider />
                                    <Box padding={1} display='flex' justifyContent='end'>
                                        <Pagination page={currentPage}
                                            count={Math.ceil(totalPages / 10)}
                                            shape="rounded"
                                            variant="outlined"
                                            color="primary"
                                            renderItem={item => {
                                                let sUrl = location.pathname
                                                if (location.search !== '') {
                                                    const qu = location.search.split("&").map(e => {
                                                        if (e.includes("page=")) {
                                                            return `${e.startsWith("?") ? "?" : ""}page=${item.page}`
                                                        }

                                                        return e + `&page=${item.page}`
                                                    }).join("&")
                                                    sUrl += qu
                                                } else {
                                                    // console.log("NOT")
                                                    sUrl += `?page=${item.page}`
                                                }

                                                return <PaginationItem
                                                    component={Link}
                                                    to={sUrl}
                                                    // to={`${location.pathname}${location.search}${item.page === 1 ? '' : `${location.search !== '' ? '&' : '?'}page=${item.page}`}`} 
                                                    type={item.type}
                                                    variant={item.variant}
                                                    selected={item.selected}
                                                    shape={item.shape}
                                                    color={item.color}
                                                    disabled={item.disabled}
                                                    page={item.page} />
                                            }} />
                                    </Box>
                                </Box>
                            },
                        }}
                        rowCount={totalPages}
                        autoHeight
                    />
                </Layout> : <NoMatch />
            }}
        </UserConsumer >
    )
}
