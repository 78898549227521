import { Box, Icon, Tab, Tabs, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Layout from '../layouts/MainLayout';
import { DarkModeConsumer } from '../lib/dark_mode_context';
import { getAdminMenuItems } from '../lib/util';
import NoMatch from '../pages/NoMatch';

interface AdminLayoutProps {
  children?: JSX.Element | JSX.Element[]
  padding?: number
  title?: string
}


export default function AdminLayout(props: AdminLayoutProps) {
  const theme = useTheme()
  const location = useLocation()

  const [currentTab, tabs] = useMemo(() => getAdminMenuItems(location.pathname), [location.pathname])

  if (!currentTab) {
    return <NoMatch />
  }

  return (
    <Layout title={props.title} padding={0}>
      <DarkModeConsumer>
        {
          (darkMode) => <Box paddingTop={.5} sx={{ bgcolor: darkMode.enabled ? 'GrayText' : theme.palette.grey[300] }}>
            <Tabs
              value={currentTab.index}
              variant="scrollable"
              scrollButtons="auto"
            >
              {
                tabs.map(e => <Tab key={e.pathname} iconPosition='start' icon={<Icon>{e.icon}</Icon>} label={e.label} component={Link} to={e.pathname} />)
              }
            </Tabs>
          </Box>
        }
      </DarkModeConsumer>
      <Box padding={3}>
        {
          props.children
        }
      </Box>
    </Layout>
  )
}
