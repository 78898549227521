import { Cancel, FiberNew, HourglassTop, KeyboardReturn, LocalShipping, LocationOn, Verified } from '@mui/icons-material'
import { Box, Button, Card, Divider, FormControl, Grid, InputLabel, MenuItem, Select, SxProps, TextField, Typography } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import moment from 'moment'
import { useCallback, useMemo, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import AdminReportItem from '../../interface/admin_report_item'
import AdminLayout from '../../layouts/AdminLayout'
import Api from '../../lib/api'


type Filters = {
  startDate: Date
  endDate: Date
  orderSource: string
  paymentStatus: string
  processingStatus: string
  paymentMode: '' | 'online' | 'cod'
};

const columns: GridColDef[] = [
  { field: 'date', headerName: 'Date', flex: 1, minWidth: 130 },
  {
    field: 'new', headerName: 'New', width: 100, type: 'number', renderHeader: (_) => {
      return <Box display="flex" alignItems="center"><FiberNew sx={{ mr: .5 }} fontSize="small" /><Typography variant='body2'>New</Typography></Box>
    },
  },
  {
    field: 'accepted', headerName: 'Accepted', width: 120, type: 'number', renderHeader: (_) => {
      return <Box display="flex" alignItems="center"><Verified color='success' sx={{ mr: .5 }} fontSize="small" /><Typography variant='body2'>Accepted</Typography></Box>
    }
  },
  {
    field: 'hold', headerName: 'Hold', width: 100, type: 'number', renderHeader: (_) => {
      return <Box display="flex" alignItems="center"><HourglassTop color='warning' sx={{ mr: .5 }} fontSize="small" /><Typography variant='body2'>Hold</Typography></Box>
    }
  },
  {
    field: 'cancelled', headerName: 'Cancelled', width: 120, type: 'number', renderHeader: (_) => {
      return <Box display="flex" alignItems="center"><Cancel color='error' sx={{ mr: .5 }} fontSize="small" /><Typography variant='body2'>Cancelled</Typography></Box>
    }
  },
  {
    field: 'dispatched', headerName: 'Dispatched', width: 120, type: 'number', renderHeader: (_) => {
      return <Box display="flex" alignItems="center"><LocalShipping color='primary' sx={{ mr: .5 }} fontSize="small" /><Typography variant='body2'>Dispatched</Typography></Box>
    }
  },
  {
    field: 'delivered', headerName: 'Delivered', width: 120, type: 'number', renderHeader: (_) => {
      return <Box display="flex" alignItems="center"><LocationOn color='success' sx={{ mr: .5 }} fontSize="small" /><Typography variant='body2'>Delivered</Typography></Box>
    }
  },
  {
    field: 'returned', headerName: 'Returned', width: 120, type: 'number', renderHeader: (_) => {
      return <Box display="flex" alignItems="center"><KeyboardReturn color='error' sx={{ mr: .5 }} fontSize="small" /><Typography variant='body2'>Returned</Typography></Box>
    }
  },
];

function TotalCardItem(props: { title: string, value: any, titleSx?: SxProps, valueSx?: SxProps, divider?: boolean }): JSX.Element {
  return <>
    <Box display="flex" px={2} py={1}>
      <Typography sx={{ flex: 1, textTransform: 'capitalize', ...props.titleSx }}>{props.title}</Typography>
      <Typography sx={props.valueSx}>{props.value}</Typography>
    </Box>
    {
      props.divider ? <Divider /> : <></>
    }
  </>
}

export default function Reports() {
  const apiHelper = useMemo(() => new Api(), [])
  const [pageState, setPageState] = useState<{
    processing: boolean,
    error: string,
    data?: AdminReportItem[]
  }>({
    processing: false,
    error: "",
  })
  const totals = useMemo(() => {
    const data = {
      "new": 0,
      "accepted": 0,
      "hold": 0,
      "cancelled": 0,
      "dispatched": 0,
      "delivered": 0,
      "returned": 0,
    }
    if (pageState.data) {
      data.new = pageState.data.map(obj => obj.new).reduce((a, b) => a + b, 0)
      data.accepted = pageState.data.map(obj => obj.accepted).reduce((a, b) => a + b, 0)
      data.hold = pageState.data.map(obj => obj.hold).reduce((a, b) => a + b, 0)
      data.cancelled = pageState.data.map(obj => obj.cancelled).reduce((a, b) => a + b, 0)
      data.dispatched = pageState.data.map(obj => obj.dispatched).reduce((a, b) => a + b, 0)
      data.delivered = pageState.data.map(obj => obj.delivered).reduce((a, b) => a + b, 0)
      data.returned = pageState.data.map(obj => obj.returned).reduce((a, b) => a + b, 0)

    }
    return data

  }, [pageState.data])

  const finalTotal = useMemo(() => Object.values(totals).reduce((a, b) => a + b, 0), [totals])

  const { handleSubmit, setError, formState: { errors }, control } = useForm<Filters>();
  const onSubmit: SubmitHandler<Filters> = async (data) => {
    if (!moment(data.startDate).isValid()) {
      setError("startDate", {
        message: "A valid date is required!"
      })
      return
    }
    if (!moment(data.endDate).isValid()) {
      setError("endDate", {
        message: "A valid date is required!"
      })
      return
    }
    if (!moment(data.endDate).isSameOrAfter(data.startDate)) {
      setError("endDate", {
        message: "End Date must be on or after start date!"
      })
      return
    }
    if (moment(data.endDate).diff(data.startDate, 'days') > 365) {
      setError("endDate", {
        message: "Difference between start date and end date can not be more than 365 days!"
      })
      return
    }

    fetchData(data)
  }

  const fetchData = useCallback(
    async (filters: Filters) => {
      setPageState(old => ({ ...old, processing: true }))
      const response = await apiHelper.generateReport({
        startDate: filters.startDate,
        endDate: filters.endDate,
        orderSource: filters.orderSource !== "" ? filters.orderSource as any : undefined,
        paymentStatus: filters.paymentStatus !== "" ? filters.paymentStatus as any : undefined,
        paymentMode: filters.paymentMode !== "" ? filters.paymentMode : undefined
      })
      if (response.ok) {
        setPageState(old => ({ ...old, processing: false, data: response.data }))
      } else {
        setPageState(old => ({ ...old, processing: false, error: response.message ?? "Something went wrong!" }))
      }
    },
    [apiHelper],
  )



  return (
    <AdminLayout title='Report'>
      <Typography variant='h5'>Filters</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container mt={0} spacing={2}>
          <Grid item xs={12} md={3} xl={2}>
            <Controller
              name="startDate"
              control={control}
              defaultValue={new Date()}
              rules={{ required: "required*" }}
              render={({ field }) => <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  {...field}
                  label="Start Date*"
                  inputFormat='dd/MM/yyyy'
                  renderInput={(params) => <TextField {...params} error={typeof errors.startDate?.message !== 'undefined'} helperText={errors.startDate?.message} fullWidth />}
                />
              </LocalizationProvider>} />
          </Grid>
          <Grid item xs={12} md={3} xl={2}>
            <Controller
              name="endDate"
              control={control}
              defaultValue={new Date()}
              rules={{ required: "required*" }}
              render={({ field }) => <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  {...field}
                  label="End Date*"
                  inputFormat='dd/MM/yyyy'
                  renderInput={(params) => <TextField {...params} error={typeof errors.endDate?.message !== 'undefined'} helperText={errors.endDate?.message} fullWidth />}
                />
              </LocalizationProvider>} />
          </Grid>
          <Grid item xs={12} md={3} xl={2}>
            <Controller
              name="orderSource"
              control={control}
              defaultValue=""
              render={({ field }) => <FormControl fullWidth>
                <InputLabel id="order_source_label">Order Source</InputLabel>
                <Select
                  {...field}
                  error={errors.orderSource?.message !== undefined}
                  labelId="order_source_label"
                  label="Order Source"
                >
                  <MenuItem value="">Any</MenuItem>
                  <Divider />
                  <MenuItem value="ARA_WEB">Website</MenuItem>
                  <Divider />
                  <MenuItem value="ARA-AD">Android</MenuItem>
                  <Divider />
                  <MenuItem value="ARA-IOS">iOS</MenuItem>
                </Select>
              </FormControl>} />
          </Grid>
          <Grid item xs={12} md={3} xl={2}>
            <Controller
              name="paymentStatus"
              control={control}
              defaultValue=""
              render={({ field }) => <FormControl fullWidth>
                <InputLabel id="payment_status_label">Payment Status</InputLabel>
                <Select
                  {...field}
                  error={errors.paymentStatus?.message !== undefined}
                  labelId="payment_status_label"
                  label="Payment Status"
                >
                  <MenuItem value="">Any</MenuItem>
                  <Divider />
                  <MenuItem value="-1">Failed</MenuItem>
                  <Divider />
                  <MenuItem value="0">Pending</MenuItem>
                  <Divider />
                  <MenuItem value="1">Success</MenuItem>
                </Select>
              </FormControl>} />
          </Grid>
          <Grid item xs={12} md={3} xl={2}>
            <Controller
              name="paymentMode"
              control={control}
              defaultValue=""
              render={({ field }) => <FormControl fullWidth>
                <InputLabel id="payment_mode_label">Payment Mode</InputLabel>
                <Select
                  {...field}
                  error={errors.paymentMode?.message !== undefined}
                  labelId="payment_mode_label"
                  label="Payment Mode"
                >
                  <MenuItem value="">Any</MenuItem>
                  <Divider />
                  <MenuItem value="online">Prepaid</MenuItem>
                  <Divider />
                  <MenuItem value="cod">COD</MenuItem>
                </Select>
              </FormControl>} />
          </Grid>
          {/* <Grid item xs={12} md={3} xl={2}>
            <Controller
              name="processingStatus"
              control={control}
              defaultValue=""
              render={({ field }) => <FormControl fullWidth sx={{ mb: 1.5 }}>
                <InputLabel id="processing_status_label">Processing Status</InputLabel>
                <Select
                  {...field}
                  error={errors.processingStatus?.message !== undefined}
                  labelId="processing_status_label"
                  label="Processing Status"
                >
                  <MenuItem value="">Any</MenuItem>
                  <Divider />
                  <MenuItem value="new">New</MenuItem>
                  <Divider />
                  <MenuItem value="accepted">Accepted</MenuItem>
                  <Divider />
                  <MenuItem value="hold">Hold</MenuItem>
                  <Divider />
                  <MenuItem value="cancel">Cancelled</MenuItem>
                  <Divider />
                  <MenuItem value="dispatch">Dispatched</MenuItem>
                  <Divider />
                  <MenuItem value="delivered">Delivered</MenuItem>
                  <Divider />
                  <MenuItem value="returned">Returned</MenuItem>
                </Select>
                <FormHelperText variant='filled' error={errors.processingStatus?.message !== undefined} >{errors.processingStatus?.message}</FormHelperText>
              </FormControl>} />
          </Grid> */}
          <Grid item xs={12} md={3} xl={2}>
            <Button variant='contained' sx={{ height: '100%' }} fullWidth type='submit' disabled={pageState.processing}>{pageState.processing ? "Please wait..." : "Generate Report"}</Button>
          </Grid>
        </Grid>

      </form>
      <Divider sx={{ my: 2 }} />
      {
        pageState.data ? <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <DataGrid
              rows={pageState.data ?? []}
              columns={columns}
              getRowId={e => e.date}
              pageSize={10}
              rowsPerPageOptions={[10]}
              autoHeight
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <Box p={1}>
                <Typography variant="h6">Totals</Typography>
              </Box>
              <Divider />
              {
                Object.entries(totals).map(([key, value]) => <TotalCardItem key={key} title={key} value={value} divider />)
              }
              <TotalCardItem title="Total" value={finalTotal} titleSx={{ fontWeight: 'bold' }} />
            </Card>
          </Grid>
        </Grid> : <></>
      }
    </AdminLayout>
  )
}
