import React from "react";
import { useLocation } from "react-router-dom";
import ARAdminMenuItem from "../interface/ar_admin_menu_item";
import ARMenu from "../interface/ar_menu_item";
import Order from "../interface/order";
import OrderStatus from "../interface/order_status";

export const saveToLocalStorage = (key: string, value: any) => {
    return new Promise((resolve, _) => {
        localStorage.setItem(key, value)
        resolve(localStorage.getItem(key))
    })
}


export const useAuth = () => {
    return localStorage.getItem("order_room_v4_token")
}

export const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const getMenuItems = (href?: string) => {
    const menuItems: ARMenu[] = [
        {
            title: "Dashboard",
            icon: 'dashboard',
            href: "/dashboard",
            permissionString: "v3_page_dashboard",
        },
        {
            title: "New Orders",
            icon: 'fiber_new',
            href: "/o/new",
            permissionString: "v3_page_new",
            status: "new"
        },
        {
            title: "Accepted Orders",
            icon: 'verified',
            href: "/o/accepted",
            permissionString: "v3_page_accept",
            status: "accepted"
        },
        {
            title: "Hold Orders",
            icon: 'hourglass_top',
            href: "/o/hold",
            permissionString: "v3_page_hold",
            status: "hold"
        },
        {
            title: "Cancelled Orders",
            icon: 'cancel',
            href: "/o/cancelled",
            permissionString: "v3_page_cancelled",
            status: "cancelled"
        },
        {
            title: "Dispatched Orders",
            icon: 'local_shipping',
            href: "/o/dispatched/in_transit",
            permissionString: "v3_page_dispatched",
            status: "dispatched",
            dLoc: "in_transit"
        },
        {
            title: "Delivered Orders",
            icon: 'location_on',
            href: "/o/dispatched/delivered",
            permissionString: "v3_page_delivered",
            status: "dispatched",
            dLoc: "delivered"
        },
        {
            title: "Returned Orders",
            icon: 'keyboard_return',
            href: "/o/dispatched/returned",
            permissionString: "v3_page_returned",
            status: "dispatched",
            dLoc: "returned"
        },
        {
            title: "Search",
            icon: 'search',
            href: "/search",
            permissionString: "v3_page_search",
        }
    ]
    if (href) {
        return menuItems.filter(f => f.href === href)[0]
    }
    return menuItems
}

export const getAdminMenuItems = (href: string = ""): [ARAdminMenuItem | undefined, ARAdminMenuItem[]] => {
    const tabList: ARAdminMenuItem[] = [
        {
            pathname: "/admin",
            label: "Home",
            icon: "home",
            index: 0
        },
        {
            pathname: "/admin/report",
            label: "Reports",
            icon: "assessment",
            index: 1
        },
        {
            pathname: "/admin/users",
            label: "Manage Users",
            icon: "account_circle",
            index: 2
        },
    ]

    return [tabList.filter(f => f.pathname === href).shift(), tabList]
}

export const detectOrderStatus = (order?: Order): OrderStatus => {
    let status: OrderStatus = {
        title: "Unknown",
        color: "error",
        description: "System could not find a valid order status!",
        key: "unknown"
    }
    if (order?.tbl_order_room?.crmid === null) {
        status = {
            title: "Pending",
            color: "warning",
            description: "Order is yet to be processed by agents.",
            key: "pending"
        }
    }

    if (order?.tbl_order_room?.status === 'verify') {
        status = {
            title: "Verified",
            color: "success",
            description: "Order has been accepted. Further processing is pending yet.",
            key: "verified"
        }

    }
    if (order?.tbl_order_room?.status === 'reject') {
        status = {
            title: "Rejected",
            color: "error",
            description: "Order has been rejected!",
            key: "rejected"
        }
    }
    if (order?.tbl_order_room?.status === 'working') {
        status = {
            title: "Working",
            color: "info",
            description: "Order is under working status.",
            key: "working"
        }

    }
    if (order?.tbl_order_room?.status === 'dispatch') {
        status = {
            title: "Dispatched",
            color: "success",
            description: "Order has been dispatched.",
            key: "dispatched"
        }

    }
    if (order?.tbl_order_room?.status === 'cancel') {
        status = {
            title: "Cancelled",
            color: "error",
            description: "Order has been cancelled.",
            key: "cancelled"
        }

    }
    if (order?.tbl_order_room?.status === 'hold') {
        status = {
            title: "Hold",
            color: "warning",
            description: "Order is on hold.",
            key: "hold"
        }

    }
    if (order?.tbl_order_room?.status === 'dispatch' && order?.tbl_order_room?.location === 'returned') {
        status = {
            title: "Returned",
            color: "error",
            description: "Order has been returned to source.",
            key: "returned"
        }

    }
    if (order?.tbl_order_room?.status === 'dispatch' && order?.tbl_order_room?.location === 'delivered') {
        status = {
            title: "Delivered",
            color: "success",
            description: "Order has been delivered successfully to the customer.",
            key: "delivered"
        }
    }
    // console.log(order?.tbl_order_room?.status, order?.tbl_order_room?.location)
    return status
}

export const orderStatusToIcon = (status: string) => {
    switch (status) {
        case 'Android':
            return 'android_outlined'
        case 'Website':
            return 'language_outlined'
        case 'iOS':
            return 'apple'
        default:
            return 'help_outlined'
    }
}

export const isInt = (data: string) => {
    return /^\d+$/.test(data)
}

export const orderSourceCodeToString = (d?: string) => {
    switch (d) {
        case 'ARA-AD':
            return 'Android'
        case 'ARA_WEB':
            return 'Website'
        case 'ARA-IOS':
            return 'iOS'
        default:
            return 'Unknown';
    }
}