import { Search as SearchIcon } from "@mui/icons-material";
import { Box, Button, Chip, CircularProgress, Divider, FormControl, Grid, Icon, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../layouts/MainLayout';
import Order from '../interface/order';
import Api from '../lib/api';
import { detectOrderStatus, orderSourceCodeToString, orderStatusToIcon } from '../lib/util';

interface SearchStateType {
    processing: boolean
    searchResult: Order[]
    searchCriteria: string
}



export default function Search() {
    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const searchTerm = useRef<string>('')
    const [searchState, setSearchState] = useState<SearchStateType>({
        processing: false,
        searchResult: [],
        searchCriteria: ""
    })
    const apiHelper = useMemo(() => new Api(), [])
    const searchData = useMemo(() => async () => {
        if (searchTerm.current.trim().length === 0) {
            showSnackbar("A valid search term is required!", {
                variant: 'error'
            })
            return
        }
        if (searchState.searchCriteria.length === 0) {
            showSnackbar("Please choose a valid search criteria!", {
                variant: 'error'
            })
            return
        }
        setSearchState(old => ({ ...old, processing: true, searchResult: [] }))
        // const response = await apiHelper.findOrder({ searchCriteria: searchState.searchCriteria, searchTerm: searchTerm.current.trim() })
        const filter: { [key: string]: any } = {}
        switch (searchState.searchCriteria) {
            case 'orderid':
                filter['orderId'] = searchTerm.current.trim()
                break;
            case 'tracking_id':
                filter['trackingId'] = searchTerm.current.trim()
                break;
            case 'crmid':
                filter['crmId'] = searchTerm.current.trim()
                break;
            case 'phone_number':
                filter['phone'] = searchTerm.current.trim()
                break;
            default:
                break;
        }
        const response = await apiHelper.fetchOrdersByStatus({
            type: "all",
            filter
        })
        if (response.ok) {
            setSearchState(old => ({ ...old, searchResult: response.data?.data ?? [], processing: false }))
            if (response.data?.data?.length === 0) {
                showSnackbar("No result with selected criteria!")
            }
        } else {
            setSearchState(old => ({ ...old, processing: false }))
            showSnackbar(response.message, {
                variant: 'error'
            })
        }
    }, [apiHelper, searchState, showSnackbar])



    const columns: GridColDef[] = useMemo(() => {
        const col = [
            {
                field: 'order_status', filterable: false, sortable: false, headerName: 'Order Status', width: 150, renderCell: (cell: any) => {
                    const d = detectOrderStatus(cell.row)
                    return <Chip label={d.title} color={d.color} />
                }
            },
            {
                field: 'created_at', filterable: false, sortable: false, description: "Date on which customer placed order", headerName: 'Order Date', width: 200, renderCell: (cell: any) => {
                    return <Box>
                        <Typography variant='subtitle2' noWrap>{moment(cell.value).format('DD-MM-YYYY')}</Typography>
                        <Typography variant='caption' noWrap>{moment(cell.value).fromNow()} at {moment(cell.value).format('hh:mm A')}</Typography>
                    </Box>
                }
            },
            {
                field: 'last_updated', filterable: false, sortable: false, description: "When was the last time this order was processed by us", headerName: 'Last Processed', width: 200, renderCell: (cell: any) => {

                    return cell.row.tbl_order_room?.updated_at !== null ? <Box>
                        <Typography variant='subtitle2' noWrap>{moment(cell.row.tbl_order_room.updated_at).format('DD-MM-YYYY')}</Typography>
                        <Typography variant='caption' noWrap>{moment(cell.row.tbl_order_room.updated_at).fromNow()} at {moment(cell.row.tbl_order_room.updated_at).format('hh:mm A')}</Typography>
                    </Box> : <Typography variant="body2" noWrap>Never</Typography>

                }
            },
            {
                field: 'orderid', filterable: false, sortable: false, description: "Customer name and order id", headerName: 'Order Info', width: 200, renderCell: (cell: any) => {
                    return <Box>
                        <Grid container columnSpacing={1}>
                            <Grid item>
                                <Typography component={Link} to={`/u/${cell.value}`} sx={{
                                    textDecoration: 'underline',
                                    fontWeight: 'bold',
                                    color: "error.main"
                                }} variant='subtitle2' noWrap>{cell.value}</Typography>
                                <Typography variant='body2' noWrap>{cell.row.user_info.fullname}</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                }
            },
            {
                field: 'status', filterable: false, sortable: false, description: "Status of payment", headerName: 'Payment Status', width: 160, renderCell: (cell: any) => {
                    return <Chip color={cell.value === 'success' ? 'success' : cell.value === 'failed' ? 'error' : cell.value === 'pending' ? 'warning' : 'default'} sx={{
                        textTransform: 'capitalize'
                    }} label={cell.value} />
                }
            },
            {
                field: 'order_source', filterable: false, sortable: false, description: "Where did the order came from?", headerName: 'Order Source', width: 160, renderCell: (cell: any) => {
                    const d = orderSourceCodeToString(cell.value)
                    return <Chip icon={<Icon>{orderStatusToIcon(d)}</Icon>} color={cell.value === 'ARA-AD' ? 'success' : cell.value === 'ARA_WEB' ? 'info' : cell.value === 'ARA-IOS' ? 'warning' : 'default'} label={d} />
                }
            },
            {
                field: 'order_items', filterable: false, sortable: false, description: "Which products did customer buy?", headerName: 'Products', width: 210, renderCell: (cell: any) => {
                    const d = `${cell.row.order_meta[0].tbl_product.name.split("-")[0]}${cell.row.order_meta.length > 1 ? ` and ${cell.row.order_meta.length - 1} others` : ''}`
                    return <Tooltip title={d}>
                        <Typography variant="body2" noWrap >{d}</Typography >
                    </Tooltip>
                }
            },
            {
                field: 'order_amount', filterable: false, sortable: false, description: "Total to be paid by the customer", headerName: 'Order Total', width: 160, renderCell: (cell: any) => {
                    return <Chip color={cell.row.payment_mode === 'online' ? 'primary' : cell.row.payment_mode === 'cod' ? 'secondary' : 'default'} sx={{
                        textTransform: 'capitalize'
                    }} label={`₹${cell.row.order_meta.map((e: any) => e.paid_amount).reduce((a: number, b: number) => a + b, 0)} - ${cell.row.payment_mode}`} />
                }
            },
            {
                field: 'phone', filterable: false, sortable: false, description: "Contact number of customer", headerName: 'Contact', width: 160, renderCell: (cell: any) => {
                    return <Typography variant="body2" noWrap>{cell.row.user_info?.phone ?? 'N/A'}</Typography>
                }
            },
            {
                field: 'location', filterable: false, sortable: false, description: "State in which products have to be delivered", headerName: 'Destination', width: 160, renderCell: (cell: any) => {
                    return <Box>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }} noWrap>{cell.row.user_info?.state ?? 'N/A'}</Typography>
                        <Typography variant="caption" noWrap>Pin: {cell.row.user_info?.pincode ?? 'N/A'}</Typography>
                    </Box>
                }
            },
        ]
        col.splice(2, 0, {
            field: 'crm_id', filterable: false, sortable: false, description: "CRM Id of the order", headerName: 'CRM Id', width: 160, renderCell: (cell: any) => {
                return <Typography variant="body2" noWrap>{cell.row.tbl_order_room?.crmid ?? 'Not assigned'}</Typography>
            }
        })
        col.splice(3, 0, {
            field: 'message', filterable: false, sortable: false, description: "Remark for this order", headerName: 'Remark', width: 160, renderCell: (cell: any) => {
                return <Tooltip title={cell.row.tbl_order_room?.disposition_desc ?? 'None'}>
                    <Typography variant="body2" noWrap>{cell.row.tbl_order_room?.disposition_desc ?? 'None'}</Typography>
                </Tooltip>
            }
        })

        col.splice(1, 0, {
            field: 'dispatch_date', filterable: false, sortable: false, description: "When did order dispatch from source?", headerName: 'Dispatch Date', width: 200, renderCell: (cell: any) => {
                return cell.row.tbl_order_room?.dispatched_at ? <Box>
                    <Typography variant='subtitle2' noWrap>{moment(cell.row.tbl_order_room?.dispatched_at).format('DD-MM-YYYY')}</Typography>
                    <Typography variant='caption' noWrap>{moment(cell.row.tbl_order_room?.dispatched_at).fromNow()} at {moment(cell.value).format('hh:mm A')}</Typography>
                </Box> : <Typography variant='subtitle2' color='error'>Not Dispatched</Typography>
            }
        })
        col.splice(3, 0, {
            field: 'courier_partners', filterable: false, sortable: false, description: "Which courier partner is going to deliver this order?", headerName: 'Courier Partner', width: 160, renderCell: (cell: any) => {
                return <Tooltip title={cell.row.tbl_order_room?.courier_partners?.alias ?? 'Not assigned'}>
                    <Typography variant="body2" noWrap>{cell.row.tbl_order_room?.courier_partners?.alias ?? 'Not assigned'}</Typography>
                </Tooltip>
            }
        })
        col.splice(4, 0, {
            field: 'tracking_id', filterable: false, sortable: false, description: "Tracking id to track this order", headerName: 'Tracking Id', width: 160, renderCell: (cell: any) => {
                return <Tooltip title={cell.row.tbl_order_room.courier_partners.tracking_link ? "Click to track" : cell.row.tbl_order_room?.tracking_id ?? 'Not assigned'}>
                    {
                        cell.row.tbl_order_room?.courier_partners?.tracking_link ? <Typography style={{ fontWeight: 'bold' }} component={'a'} href={
                            `${cell.row.tbl_order_room?.courier_partners?.tracking_link}${cell.row.tbl_order_room.tracking_id}`
                        } color='error.main' target="_blank" variant="body2" noWrap>
                            {cell.row.tbl_order_room?.tracking_id ?? 'Not assigned'}
                        </Typography> : <Typography variant="body2" noWrap>{cell.row.tbl_order_room?.tracking_id ?? 'Not assigned'}</Typography>
                    }
                </Tooltip >
            }
        })
        return col
    }, [])


    return (
        <Layout title='Search Order'>
            <Grid container spacing={2}>
                <Grid item xs={12} md={7}>
                    <TextField onChange={e => searchTerm.current = e.target.value} fullWidth id='search' sx={{ flex: 2 }} variant='outlined' placeholder='Search here with Order Id / CRM Id / Tracking Number / Phone Number' label='Search here' autoFocus />
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Search type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="search-type"
                            label="Search type"
                            value={searchState.searchCriteria}
                            onChange={e => setSearchState(old => ({ ...old, searchCriteria: e.target.value }))}
                        >
                            <MenuItem value='orderid'>Order Id</MenuItem>
                            <MenuItem value='tracking_id'>Tracking Id</MenuItem>
                            <MenuItem value='crmid'>CRM Id</MenuItem>
                            <MenuItem value='phone_number'>Phone Number</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Button startIcon={<SearchIcon />} sx={{
                        height: '100%'
                    }} disabled={searchState.processing} fullWidth variant='outlined' onClick={searchData}>Search</Button>
                </Grid>
            </Grid>
            <Divider sx={{
                marginY: 2
            }} />
            {
                searchState.processing ? <Box display='flex' alignItems='center'>
                    <CircularProgress size={20} />
                    <Typography sx={{ marginLeft: 1.5 }}>Searching...</Typography>
                </Box> : <></>
            }
            {
                searchState.searchResult.length > 0 ? <Box>
                    <Typography>Found {searchState.searchResult.length} results...</Typography>
                    <DataGrid
                        sx={{
                            marginTop: 2
                        }}
                        rows={searchState.searchResult}
                        columns={columns}
                        pageSize={10}
                        getRowId={e => e.orderid}
                        autoHeight
                        rowsPerPageOptions={[10]}
                    />
                </Box> : <></>
            }
        </Layout>
    )
}
