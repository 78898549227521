import axios, { Axios, AxiosError } from "axios"
import moment from "moment"
import EmptyObjectError from "../errors/EmptyObjectError"
import AdminReportItem from "../interface/admin_report_item"
import ApiResponse from "../interface/api_response"
import CourierPartner from "../interface/courier_partner"
import getAnalyticsResponse from "../interface/getAnalyticsResponse"
import Order from "../interface/order"
import PostOffice from "../interface/post_office"

interface loginProps {
    username: string
    password: string
}

interface generateReportProps {
    startDate: Date,
    endDate: Date
    orderSource?: "ARA_WEB" | "ARA-AD" | "ARA-IOS"
    paymentStatus?: "-1" | "1" | "0"
    processingStatus?: "new" | "accepted" | "hold" | "cancelled" | "dispatched" | "delivered" | "returned"
    paymentMode?: "online" | "cod"
}

interface fetchOrderProps {
    type: "all" | "new" | "accepted" | "hold" | "cancelled" | "dispatched"
    dLoc?: string
    page?: number
    limit?: number
    filter?: {
        orderId?: number,
        crmId?: number,
        phone?: number,
        trackingId?: string,
        startDate?: Date,
        endDate?: Date
        orderSource?: "ARA_WEB" | "ARA-AD" | "ARA-IOS"
        paymentStatus?: "-1" | "1" | "0"
        processingStatus?: "new" | "accepted" | "hold" | "cancelled" | "dispatched" | "delivered" | "returned"
    }
}

interface fetchOrderResponse {
    total: number
    data: Order[]
}

interface findOrderProps {
    searchTerm: string
    searchCriteria: string
}

export default class Api {
    private token?: string
    private axios: Axios
    constructor() {
        this.token = localStorage.getItem('order_room_v4_token') ?? undefined
        this.axios = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            headers: this.token !== undefined ? {
                'authorization': `Bearer ${this.token}`
            } : {}
        })
        this.axios.interceptors.response.use((response) => response, (axiosError: AxiosError) => {
            if (axiosError.config.url !== '/auth/login' && axiosError.response?.status === 401) {
                localStorage.clear()
                if (window.location.pathname !== '/login') {
                    window.location.reload()
                }
            }
            return Promise.reject(axiosError)
        })
    }

    async login(data: loginProps): Promise<ApiResponse<any>> {
        try {
            const response = await this.axios.post("/auth/login", data)

            return {
                ok: true,
                data: response.data
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    ok: false,
                    message: (error.response?.data as any)?.message ?? "Something went wrong!"
                }
            }
            // 
            return {
                ok: false,
                message: "Something went wrong!"
            }
        }
    }

    async fetchOrdersByStatus({ type, limit, page, dLoc, filter }: fetchOrderProps): Promise<ApiResponse<fetchOrderResponse>> {
        try {
            const query: { [key: string]: any } = {}
            if (page) {
                query['page'] = page
            }
            if (limit) {
                query['limit'] = limit
            }
            if (dLoc) {
                query['location'] = dLoc
            }
            if (filter) {
                Object.entries(filter).forEach(([key, value]) => {
                    if (value instanceof Date) {
                        query[key] = moment(value).toISOString()
                    } else {
                        query[key] = value
                    }
                })
            }
            const queries = Object.entries(query).map((([key, value]) => {
                return `${key}=${value}`
            })).join("&")

            const response = await this.axios.get(`/order-room/${type}${queries.length > 0 ? `?${queries}` : ''}`)


            console.log(response.data);

            return {
                ok: true,
                data: response.data
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    ok: false,
                    message: (error.response?.data as any)?.message ?? "Something went wrong!"
                }
            }

            return {
                ok: false,
                message: "Something went wrong!"
            }
        }
    }

    async ping(): Promise<ApiResponse<boolean>> {
        try {
            await this.axios.get("/")
            // console.log(response)
            return {
                ok: true,
                data: true
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    ok: false,
                    message: (error.response?.data as any)?.message ?? "Something went wrong!"
                }
            }

            return {
                ok: false,
                message: "Something went wrong!"
            }
        }
    }

    async findOrder({ searchCriteria, searchTerm }: findOrderProps): Promise<ApiResponse<Order[]>> {
        try {
            const response = await this.axios.get(`/order-room/find?q=${encodeURIComponent(searchTerm)}&c=${searchCriteria}`)
            // console.log(response)
            return {
                ok: true,
                data: response.data
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    ok: false,
                    message: (error.response?.data as any)?.message ?? "Something went wrong!"
                }
            }

            return {
                ok: false,
                message: "Something went wrong!"
            }
        }
    }

    async fetchDashboardAnalytics(rangeString: string): Promise<ApiResponse<{
        today: getAnalyticsResponse
        yesterday?: getAnalyticsResponse
    }>> {
        try {
            const response = await this.axios.get(`/dashboard${rangeString !== '' ? `?${rangeString}` : ''}`)
            // console.log(response)
            return {
                ok: true,
                data: response.data.data
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    ok: false,
                    message: (error.response?.data as any)?.message ?? "Something went wrong!"
                }
            }

            return {
                ok: false,
                message: "Something went wrong!"
            }
        }
    }

    async generateReport(filters: generateReportProps): Promise<ApiResponse<AdminReportItem[]>> {
        try {
            const response = await this.axios.get<AdminReportItem[]>(`/admin/report`, {
                params: {
                    ...filters,
                    startDate: moment(filters.startDate).format("YYYY-MM-DD"),
                    endDate: moment(filters.endDate).format("YYYY-MM-DD")
                }
            })
            // console.log(response)
            return {
                ok: true,
                data: response.data
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    ok: false,
                    message: (error.response?.data as any)?.message ?? "Something went wrong!"
                }
            }

            return {
                ok: false,
                message: "Something went wrong!"
            }
        }
    }

    async updateOrder(orderId: bigint, body: { [key: string]: any }): Promise<ApiResponse<boolean>> {
        try {
            await this.axios.patch(`/order-room/process-order/${orderId}`, body)
            return {
                ok: true,
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    ok: false,
                    message: (error.response?.data as any)?.message ?? "Something went wrong!"
                }
            }

            return {
                ok: false,
                message: "Something went wrong!"
            }
        }

    }

    async listCourierPartner(): Promise<ApiResponse<CourierPartner[]>> {
        try {
            const response = await this.axios.get<CourierPartner[]>(`/courier`)
            return {
                ok: true,
                data: response.data
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    ok: false,
                    message: (error.response?.data as any)?.message ?? "Something went wrong!"
                }
            }

            return {
                ok: false,
                message: "Something went wrong!"
            }
        }

    }

    async updateOrderMeta(orderId: bigint | number, type: 'userMeta' | 'orderMeta' | 'productMeta', body: { [key: string]: any }): Promise<ApiResponse<boolean>> {
        try {
            await this.axios.patch(`/order-room/order-meta/${orderId}/${type}`, body)
            return {
                ok: true,
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    ok: false,
                    message: (error.response?.data as any)?.message ?? "Something went wrong!"
                }
            }
            // console.log(error)
            return {
                ok: false,
                message: "Something went wrong!"
            }
        }

    }

    async findPincodeDetails(pincode: string): Promise<ApiResponse<PostOffice[]>> {
        try {
            const response = await axios.get(`https://api.postalpincode.in/pincode/${pincode}`)
            if ((response.data[0].PostOffice === null)) {
                throw new EmptyObjectError("You have probably provided an invalid pincode!")
            }
            return {
                ok: true,
                data: response.data[0].PostOffice as PostOffice[]
            }
        } catch (error) {
            if (error instanceof EmptyObjectError) {
                return {
                    ok: false,
                    message: error.message
                }
            }

            return {
                ok: false,
                message: "Something went wrong!"
            }
        }

    }

}