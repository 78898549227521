import { Button, Dialog, DialogContent, DialogTitle, Grid, TextField } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import moment from 'moment';
import { Controller, SubmitHandler, useForm } from "react-hook-form"

type DateRangePickerDialogInput = {
    startDate: Date
    endDate: Date
};

interface DateRangePickerDialogProps {
    open: boolean
    startInit?: Date
    endInit?: Date
    onClose: () => void
    onFilter: (startDate: Date, endDate: Date) => void
}

export default function DateRangePickerDialog(props: DateRangePickerDialogProps) {
    const { handleSubmit, control, formState: { errors }, setError, reset, clearErrors } = useForm<DateRangePickerDialogInput>();

    const handleDialogClose = () => {
        reset()
        clearErrors()
        props.onClose()
    }

    const onSubmit: SubmitHandler<DateRangePickerDialogInput> = data => {
        if (!moment(data.startDate).isValid()) {
            setError("startDate", {
                message: "A valid date is required!"
            })
            return
        }
        if (!moment(data.endDate).isValid()) {
            setError("endDate", {
                message: "A valid date is required!"
            })
            return
        }
        props.onFilter(data.startDate, data.endDate)
        handleDialogClose()
    };



    return (
        <Dialog open={props.open}>
            <DialogTitle>Select custom range</DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container py={2} spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                            <Controller
                                control={control}
                                name="startDate"
                                defaultValue={props.startInit ?? new Date()}
                                rules={{ required: "Required*" }}
                                render={({ field }) => <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        inputFormat='dd/MM/yyyy'
                                        label="Range from"
                                        {...field}
                                        renderInput={(params) => <TextField fullWidth {...params} helperText={errors.startDate?.message} error={typeof errors.startDate !== 'undefined'} />}
                                    />
                                </LocalizationProvider>} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Controller
                                control={control}
                                name="endDate"
                                defaultValue={props.endInit ?? new Date()}
                                rules={{ required: "Required*" }}
                                render={({ field }) => <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        inputFormat='dd/MM/yyyy'
                                        label="Range to"
                                        {...field}
                                        renderInput={(params) => <TextField fullWidth {...params} helperText={errors.endDate?.message} error={typeof errors.endDate !== 'undefined'} />}
                                    />
                                </LocalizationProvider>} />
                        </Grid>
                    </Grid>
                    <Grid container mt={2} justifyContent="right" spacing={2}>
                        <Grid item>
                            <Button variant='contained' color="error" onClick={handleDialogClose}>Cancel</Button>
                        </Grid>
                        <Grid item>
                            <Button variant='contained' color="success" type="submit">Filter</Button>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog>
    )
}
// props.onFilter(dialogState.startDate, d)
// navigator({
//     pathname: location.pathname,
//     search: `?range=custom&start=${moment(pageStateCustom.customRangeStart).format("YYYY-MM-DD")}&end=${moment(pageStateCustom.customRangeEnd).format("YYYY-MM-DD")}`
// }, { replace: true })