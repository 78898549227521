import { Button, Grid, Typography, useTheme } from '@mui/material'
import { Link } from 'react-router-dom'
import Helmet from "react-helmet";

export default function NoMatch() {
  const theme = useTheme()
  return (
    <Grid bgcolor={theme.palette.background.default} container justifyContent='center' alignItems='center' height='100vh'>
      <Helmet>
        <title>404: Page not found</title>
      </Helmet>
      <Grid item textAlign='center'>
        <Typography variant='h2' color={theme.palette.text.primary} >404</Typography>
        <Typography color={theme.palette.text.primary}>Page not found</Typography>
        <Button sx={{
          marginTop: 2
        }} variant='contained' component={Link} to="/" >
          Go to home page
        </Button>
      </Grid>
    </Grid>
  )
}
