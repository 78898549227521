import { Alert, AlertTitle, Box, Button, Dialog, DialogContent, Divider, FormControl, FormHelperText, Grid, InputLabel, LinearProgress, MenuItem, Select, Typography } from '@mui/material'
import { useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import Order from '../../../interface/order'
import Api from '../../../lib/api'

interface UpdatePaymentInfoDialogProps {
    open: boolean
    order: Order
    apiHelper: Api
    onClose?: (e: any) => any
}

type Inputs = {
    paymentMode: string,
    paymentStatus: string
};

export default function UpdatePaymentInfoDialog(props: UpdatePaymentInfoDialogProps) {
    const { handleSubmit, formState: { errors }, reset, control } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = async (data) => {

        if (props.order.status === data.paymentStatus && props.order.payment_mode === data.paymentMode) {
            setDialogState({
                error: "Nothing to update!",
                processing: true
            })
            return
        }
        setDialogState({
            error: "",
            processing: true
        })

        let paymentStatus = ""
        switch (data.paymentStatus) {
            case "failed":
                paymentStatus = "-1"
                break;
            case "success":
                paymentStatus = "1"
                break;
            case "pending":
                paymentStatus = "0"
                break;
            default:
                break
        }
        if (paymentStatus === "") {
            setDialogState({
                error: "Payment status determination failed!",
                processing: false
            })
        }

        const response = await props.apiHelper.updateOrderMeta(props.order.orderid, 'orderMeta', {
            payment_mode: data.paymentMode,
            status: paymentStatus
        })
        if (response.ok) {
            window.location.reload()
        } else {
            setDialogState({
                error: response.message!,
                processing: false
            })
        }
    };
    const [dialogState, setDialogState] = useState({
        processing: false,
        error: ''
    })

    const handleClose = () => {
        reset()
        setDialogState({
            processing: false,
            error: ''
        })
        if (typeof props.onClose !== 'undefined') {
            props.onClose('ok')
        }
    }

    return <Dialog open={props.open} fullWidth>
        <DialogContent>
            <Typography variant='h6'>Update payment information</Typography>
            <Typography>{`#${props.order.orderid}`}</Typography>
        </DialogContent>
        <Divider />
        {
            dialogState.processing ? <LinearProgress /> : <></>
        }
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
                {
                    dialogState.error !== '' ? <Box mb={3}>
                        <Alert severity="error">
                            <AlertTitle>Error</AlertTitle>
                            {dialogState.error}
                        </Alert>
                    </Box> : <></>
                }
                <Controller
                    name="paymentMode"
                    control={control}
                    defaultValue={props.order.payment_mode}
                    rules={{ required: "Select a payment mode!" }}
                    render={({ field }) => <FormControl fullWidth sx={{ mb: 1.5 }}>
                        <InputLabel id="payment_mode_label_id">Payment Mode</InputLabel>
                        <Select
                            {...field}
                            error={errors.paymentMode?.message !== undefined}
                            labelId="payment_mode_label_id"
                            label="Courier Partner"
                        >
                            <MenuItem value='online'>Online</MenuItem>
                            <MenuItem value='cod'>COD</MenuItem>
                        </Select>
                        <FormHelperText variant='filled' error={errors.paymentMode?.message !== undefined} >{errors.paymentMode?.message}</FormHelperText>
                    </FormControl>} />
                <Controller
                    name="paymentStatus"
                    control={control}
                    defaultValue={props.order.status}
                    rules={{ required: "Select a payment status!" }}
                    render={({ field }) => <FormControl fullWidth sx={{ mb: 1.5 }}>
                        <InputLabel id="payment_status_label_id">Payment Status</InputLabel>
                        <Select
                            {...field}

                            error={errors.paymentStatus?.message !== undefined}
                            labelId="payment_status_label_id"
                            label="Courier Partner"
                        >
                            <MenuItem value="success">Success</MenuItem>
                            <MenuItem value="failed">Failed</MenuItem>
                            <MenuItem value="pending">Pending</MenuItem>
                        </Select>
                        <FormHelperText variant='filled' error={errors.paymentStatus?.message !== undefined} >{errors.paymentStatus?.message}</FormHelperText>
                    </FormControl>} />
            </DialogContent>
            <Divider />
            <DialogContent>
                <Grid width="100%" container>
                    <Grid sx={{ paddingRight: .5 }} justifyContent='center' item sm={12} md={6}>
                        <Button disabled={dialogState.processing} fullWidth variant='contained' color='error' onClick={handleClose}>Cancel</Button>
                    </Grid>
                    <Grid sx={{ paddingLeft: .5 }} justifyContent='center' item sm={12} md={6}>
                        <Button disabled={dialogState.processing} type="submit" fullWidth variant='contained' color='success'>Update</Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </form>
    </Dialog>
}
