import { Button, Divider, Grid, Menu, MenuItem, Typography } from '@mui/material';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Budget } from '../components/Budget';
import DateRangePickerDialog from '../components/Dashboard/DateRangePickerDialog';
import Layout from '../layouts/MainLayout';
import getAnalyticsResponse from '../interface/getAnalyticsResponse';
import Api from '../lib/api';
import { useQuery } from '../lib/util';

interface DashboardData {
  today: getAnalyticsResponse
  yesterday?: getAnalyticsResponse
}

const rangeTypeToDate = (range?: string, customStart?: Date, customEnd?: Date) => {
  if (customStart && customEnd) {
    return [moment(customStart).format("YYYY-MM-DD"), moment(customEnd).format("YYYY-MM-DD")]
  }
  switch (range) {
    case 'today':
      return [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")]
    case 'yesterday':
      return [moment().subtract(1, 'day').format("YYYY-MM-DD"), moment().subtract(1, 'day').format("YYYY-MM-DD")]
    case 'week':
      return [moment().subtract(1, 'week').format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")]
    case '28days':
      return [moment().subtract(28, 'days').format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")]
    default:
      return ["2020-01-01", moment().format("YYYY-MM-DD")]
  }
}

const rangeTypeToName = (range?: string) => {
  switch (range) {
    case 'yesterday':
      return "Yesterday"
    case 'week':
      return "Last 1 week"
    case '28days':
      return "Last 28 days"
    case 'custom':
      return "Custom range"
    default:
      return "Lifetime"
  }
}

export default function Dashboard() {
  const navigator = useNavigate()
  const location = useLocation()
  const query = useQuery()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const api = useMemo(() => new Api(), [])
  const [startDate, endDate] = useMemo(() => {
    const response: Date[] = []
    const sD: any = query.get("start")
    const eD: any = query.get("end")
    if (sD !== null && moment(sD).isValid()) response.push(moment(sD).toDate())
    if (eD !== null && moment(eD).isValid()) response.push(moment(eD).toDate())
    if (response.length === 2) return response
    return []
  }, [query])
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const inputRange = useMemo(() => query.get("range"), [query])
  const [pageStateToday, setPageStateToday] = useState<{
    processing: boolean,
    data?: DashboardData,
    error?: string
  }>({
    processing: false,
    data: undefined
  })

  const [pageStateCustom, setPageStateCustom] = useState<{
    processing: boolean,
    data?: DashboardData,
    error?: string,
    customRangeDialogOpen?: boolean
  }>({
    processing: false,
  })

  const handleAnalyticRangeChange = (option: 'today' | 'yesterday' | 'week' | '28days' | 'lifetime') => {
    navigator({
      pathname: location.pathname,
      search: `?range=${option}`
    }, {
      replace: true
    })
    handleClose()
  }

  const handleClose = () => {
    setAnchorEl(null);
  };




  const fetchAnalytics = useCallback(
    async (rangeString: string = "") => {
      return await api.fetchDashboardAnalytics(rangeString)
    },
    [api],
  )

  const fetchToday = useCallback(
    async () => {
      setPageStateToday({
        processing: true
      })
      const response = await fetchAnalytics()
      if (response.ok) {
        setPageStateToday({
          processing: false,
          data: response.data
        })
      } else {
        setPageStateToday({
          processing: false,
          error: response.message
        })
      }
    },
    [fetchAnalytics],
  )

  const fetchCustom = useCallback(
    async () => {
      setPageStateCustom({
        processing: true,
      })
      let rangeType = rangeTypeToDate(inputRange ?? undefined, startDate, endDate)
      let rangeString = `dateStart=${rangeType[0]}&dateEnd=${rangeType[1]}`
      // console.log("Range String: ", rangeString)
      const response = await fetchAnalytics(rangeString)
      if (response.ok) {
        setPageStateCustom({
          processing: false,
          data: response.data
        })
      } else {
        setPageStateCustom({
          processing: false,
          error: response.message
        })
      }
    },
    [fetchAnalytics, inputRange, startDate, endDate],
  )

  const handleFilterTrigger = (startDate: Date, endDate: Date) => {
    navigator({
      pathname: location.pathname,
      search: `?range=custom&start=${moment(startDate).format("YYYY-MM-DD")}&end=${moment(endDate).format("YYYY-MM-DD")}`
    }, { replace: true })
  }

  useEffect(() => {
    fetchToday()
  }, [fetchToday])


  useEffect(() => {
    fetchCustom()
  }, [fetchCustom])


  return (
    <Layout title='Dashboard' >
      <Typography sx={{ mb: 2 }} variant='h6' color='primary.main'>TODAY'S ANALYTICS</Typography>
      {
        pageStateToday.error ? <Typography color="error">{pageStateToday.error}</Typography> : <Grid container spacing={3}>
          <Grid
            item
            lg={4}
            sm={6}
            xl={3}
            xs={12}
          >
            <Budget title='New Orders' value={pageStateToday.processing ? "Loading..." : pageStateToday?.data?.today?.new?.toString() ?? "N/A"} icon='fiber_new' iconColor='primary.main' />
          </Grid>
          <Grid
            item
            lg={4}
            sm={6}
            xl={3}
            xs={12}
          >
            <Budget title='Accepted Orders' value={pageStateToday.processing ? "Loading..." : pageStateToday?.data?.today?.accepted?.toString() ?? "N/A"} icon='verified' iconColor='success.main' />
          </Grid>
          <Grid
            item
            lg={4}
            sm={6}
            xl={3}
            xs={12}
          >
            <Budget title='Hold Orders' value={pageStateToday.processing ? "Loading..." : pageStateToday?.data?.today?.hold?.toString() ?? "N/A"} icon='hourglass_top' iconColor='warning.main' />
          </Grid>
          <Grid
            item
            lg={4}
            sm={6}
            xl={3}
            xs={12}
          >
            <Budget title='Cancelled Orders' value={pageStateToday.processing ? "Loading..." : pageStateToday?.data?.today?.cancelled?.toString() ?? "N/A"} icon='cancel' iconColor='error.main' />
          </Grid>
          <Grid
            item
            lg={4}
            sm={6}
            xl={3}
            xs={12}
          >
            <Budget title='Dispatched Orders' value={pageStateToday.processing ? "Loading..." : pageStateToday?.data?.today?.dispatched?.toString() ?? "N/A"} icon='local_shipping' iconColor='info.main' />
          </Grid>
          <Grid
            item
            lg={4}
            sm={6}
            xl={3}
            xs={12}
          >
            <Budget title='Delivered Orders' value={pageStateToday.processing ? "Loading..." : pageStateToday?.data?.today?.delivered?.toString() ?? "N/A"} icon='pin_drop' iconColor='success.main' />
          </Grid>
          <Grid
            item
            lg={4}
            sm={6}
            xl={3}
            xs={12}
          >
            <Budget title='Returned Orders' value={pageStateToday.processing ? "Loading..." : pageStateToday?.data?.today?.returned?.toString() ?? "N/A"} icon='keyboard_return' iconColor='error.main' />
          </Grid>
        </Grid>
      }
      <Divider sx={{ my: 3 }} />
      <Grid container mb={2}>
        <Grid item flex={1}>
          <Typography sx={{ textTransform: 'uppercase' }} variant='h6' color='primary.main'>{`${rangeTypeToName(query.get("range") ?? "Undefined")} Analytics`}</Typography>
        </Grid>
        <Grid item>
          <Button variant="outlined"
            aria-haspopup="true"
            id="analytics-menu-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >{rangeTypeToName(query.get("range") ?? undefined)}</Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={_ => handleAnalyticRangeChange('yesterday')}>Yesterday</MenuItem>
            <MenuItem onClick={_ => handleAnalyticRangeChange('week')}>Last 1 Week</MenuItem>
            <MenuItem onClick={_ => handleAnalyticRangeChange('28days')}>Last 28 days</MenuItem>
            <MenuItem onClick={_ => handleAnalyticRangeChange('lifetime')}>Lifetime</MenuItem>
            <MenuItem disabled={pageStateCustom.processing} onClick={_ => {
              handleClose()
              setPageStateCustom(old => ({ ...old, customRangeDialogOpen: true }))
            }}>Custom Range</MenuItem>
          </Menu>
        </Grid>
      </Grid>
      {
        pageStateCustom.error ? <Typography color="error">{pageStateCustom.error}</Typography> : <Grid container spacing={3}>
          <Grid
            item
            lg={4}
            sm={6}
            xl={3}
            xs={12}
          >
            <Budget title='New Orders' value={pageStateCustom.processing ? "Loading..." : pageStateCustom?.data?.today?.new?.toString() ?? "N/A"} icon='fiber_new' iconColor='primary.main' />
          </Grid>
          <Grid
            item
            lg={4}
            sm={6}
            xl={3}
            xs={12}
          >
            <Budget title='Accepted Orders' value={pageStateCustom.processing ? "Loading..." : pageStateCustom?.data?.today?.accepted?.toString() ?? "N/A"} icon='verified' iconColor='success.main' />
          </Grid>
          <Grid
            item
            lg={4}
            sm={6}
            xl={3}
            xs={12}
          >
            <Budget title='Hold Orders' value={pageStateCustom.processing ? "Loading..." : pageStateCustom?.data?.today?.hold?.toString() ?? "N/A"} icon='hourglass_top' iconColor='warning.main' />
          </Grid>
          <Grid
            item
            lg={4}
            sm={6}
            xl={3}
            xs={12}
          >
            <Budget title='Cancelled Orders' value={pageStateCustom.processing ? "Loading..." : pageStateCustom?.data?.today?.cancelled?.toString() ?? "N/A"} icon='cancel' iconColor='error.main' />
          </Grid>
          <Grid
            item
            lg={4}
            sm={6}
            xl={3}
            xs={12}
          >
            <Budget title='Dispatched Orders' value={pageStateCustom.processing ? "Loading..." : pageStateCustom?.data?.today?.dispatched?.toString() ?? "N/A"} icon='local_shipping' iconColor='info.main' />
          </Grid>
          <Grid
            item
            lg={4}
            sm={6}
            xl={3}
            xs={12}
          >
            <Budget title='Delivered Orders' value={pageStateCustom.processing ? "Loading..." : pageStateCustom?.data?.today?.delivered?.toString() ?? "N/A"} icon='pin_drop' iconColor='success.main' />
          </Grid>
          <Grid
            item
            lg={4}
            sm={6}
            xl={3}
            xs={12}
          >
            <Budget title='Returned Orders' value={pageStateCustom.processing ? "Loading..." : pageStateCustom?.data?.today?.returned?.toString() ?? "N/A"} icon='keyboard_return' iconColor='error.main' />
          </Grid>
        </Grid>
      }
      <DateRangePickerDialog open={pageStateCustom.customRangeDialogOpen ?? false} onClose={() => setPageStateCustom(old => ({ ...old, customRangeDialogOpen: false }))} onFilter={handleFilterTrigger} startInit={startDate} endInit={endDate} />
    </Layout>
  )
}
