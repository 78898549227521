import { createTheme, ThemeProvider } from '@mui/material';
import { pink, purple } from '@mui/material/colors';
import jwtDecoder from "jwt-decode";
import { useMemo, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import AdminProtectedRoute from './components/AdminProtectedRoute';
import ProtectedRoute from './components/ProtectedRoute';
import { DarkModeProvider } from './lib/dark_mode_context';
import { UserProvider } from './lib/user_context';
import { Dashboard, Login } from './pages';
import AdminLandingPage from './pages/Admin/AdminLandingPage';
import Reports from './pages/Admin/Reports';
import UserManagement from './pages/Admin/UserManagement';
import LandingPage from './pages/LandingPage';
import NoMatch from './pages/NoMatch';
import Search from './pages/Search';
import Types from './pages/Types';
import UpdateOrder from './pages/UpdateOrder';

function App() {
  const [darkModeEnabled, setDarkModeEnabled] = useState(localStorage.getItem("order_room_v4_dark_preference") !== null)
  const [name, role, permissions] = useMemo(() => {
    const token = localStorage.getItem("order_room_v4_token")
    if (token === null) {
      return [undefined, undefined]
    }
    const decodedToken = JSON.parse(JSON.stringify(jwtDecoder(token!)))
    const permissions = decodedToken.permission.split(",")
    return [decodedToken.name ?? "Anonymous", decodedToken.role, permissions]
  }, [])

  const theme = useMemo(
    () =>
      createTheme({
        palette: darkModeEnabled ? {
          mode: 'dark'
        } : {
          primary: {
            main: purple[700]
          },
          secondary: {
            main: pink[400]
          },
          mode: 'light',
        },
      }),
    [darkModeEnabled],
  );

  const handleThemeChange = (value: boolean) => {
    if (value) {
      localStorage.setItem("order_room_v4_dark_preference", "true")
      setDarkModeEnabled(true)
    } else {
      localStorage.removeItem("order_room_v4_dark_preference")
      setDarkModeEnabled(false)
    }
  }

  return (
    <UserProvider value={{
      name,
      role,
      permissions
    }}>
      <DarkModeProvider value={{ enabled: darkModeEnabled, setEnabled: (value) => handleThemeChange(value) }}>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route element={<ProtectedRoute />}>
              <Route path='/' element={<LandingPage />} />
              <Route path='/dashboard' element={(permissions?.includes("v3_page_dashboard") ?? false) ? <Dashboard /> : <NoMatch />} />
              <Route path='/o/:type'>
                <Route path=':location' element={<Types />} />
                <Route path='' element={<Types />} />
              </Route>
              <Route path='/u/:oid' element={(permissions?.includes("v3_page_view_info") ?? false) ? <UpdateOrder /> : <NoMatch />} />
              <Route path='/search' element={(permissions?.includes("v3_page_search") ?? false) ? <Search /> : <NoMatch />} />
              <Route element={<AdminProtectedRoute />}>
                <Route path='/admin' element={<AdminLandingPage />} />
                <Route path='/admin/report' element={<Reports />} />
                <Route path='/admin/users' element={<UserManagement />} />
              </Route>
            </Route>
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </ThemeProvider>
      </DarkModeProvider>
    </UserProvider>
  );
}

export default App;
