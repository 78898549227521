import { Alert, AlertTitle, Box, Button, Dialog, DialogContent, Divider, FormControl, FormHelperText, Grid, InputLabel, LinearProgress, MenuItem, Select, TextField, Typography } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import CourierPartner from '../../interface/courier_partner'
import Order from '../../interface/order'
import Api from '../../lib/api'
import { useQuery } from '../../lib/util'

interface DispatchOrderDialogProps {
    open: boolean
    order: Order
    apiHelper: Api
    onClose?: (e: any) => any
}


type Inputs = {
    courierPartnerId: string,
    trackingId: string,
    dispatchedAt: Date
};


export default function DispatchOrderDialog(props: DispatchOrderDialogProps) {
    const navigator = useNavigate()
    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const query = useQuery()
    const callbackUrl = useMemo(() => query.get('callback'), [query])
    const { register, handleSubmit, formState: { errors }, reset, control } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        // console.log(data)
        setDialogState(old => ({
            ...old,
            error: "",
            processing: true
        }))
        const response = await props.apiHelper.updateOrder(props.order.orderid, {
            status: 'dispatch',
            courier_service: data.courierPartnerId,
            tracking_id: data.trackingId,
            dispatched_at: moment(data.dispatchedAt).format("YYYY-MM-DD HH:mm:ss")
        })
        if (response.ok) {
            navigator(callbackUrl ? decodeURIComponent(callbackUrl) : "/", {
                replace: true
            })
        } else {
            setDialogState(old => ({
                ...old,
                error: response.message,
                processing: false
            }))
        }
    };
    const [dialogState, setDialogState] = useState<{
        processing: boolean
        error?: string
        courierPartners?: CourierPartner[]
    }>({
        processing: false,
        error: '',
        courierPartners: []
    })

    const handleClose = useCallback(() => {
        reset()
        setDialogState({
            processing: false,
            error: '',
            courierPartners: []
        })
        if (typeof props.onClose !== 'undefined') {
            props.onClose('ok')
        }
    }, [props, reset])


    const loadCourierPartner = useCallback(async () => {
        if (!props.open) return
        setDialogState(old => ({ ...old, processing: true }))
        const response = await props.apiHelper.listCourierPartner()
        if (response.ok) {
            setDialogState({
                processing: false,
                error: '',
                courierPartners: response.data
            })
        } else {
            showSnackbar("This functionality is unavailable for now.", {
                variant: 'error'
            })
            handleClose()
        }
    }, [props, handleClose, showSnackbar])

    useEffect(() => {
        loadCourierPartner()
    }, [loadCourierPartner])


    return <Dialog open={props.open} fullWidth>
        <DialogContent>
            <Typography variant='h6'>Dispatch Order</Typography>
            <Typography>{`Dispatch order: ${props.order.orderid}`}</Typography>
        </DialogContent>
        <Divider />
        {
            dialogState.processing ? <LinearProgress /> : <></>
        }
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
                {
                    dialogState.error !== '' ? <Box mb={3}>
                        <Alert severity="error">
                            <AlertTitle>Error</AlertTitle>
                            {dialogState.error}
                        </Alert>
                    </Box> : <></>
                }
                <Controller
                    name="courierPartnerId"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Select a courier partner!" }}
                    render={({ field }) => <FormControl fullWidth sx={{ mb: 1.5 }}>
                        <InputLabel id="courier_partner_label_id">Courier Partner</InputLabel>
                        <Select
                            {...field}
                            error={errors.courierPartnerId?.message !== undefined}
                            labelId="courier_partner_label_id"
                            label="Courier Partner"
                        >
                            {
                                dialogState.courierPartners?.map(e => <MenuItem key={e.codename} value={e.codename}>{e.alias}</MenuItem>)
                            }
                        </Select>
                        <FormHelperText variant='filled' error={errors.courierPartnerId?.message !== undefined} >{errors.courierPartnerId?.message}</FormHelperText>
                    </FormControl>} />
                <TextField {...register("trackingId", {
                    required: "Tracking Id is required."
                })} sx={{ marginBottom: 2 }} label="Tracking Id" fullWidth={true} error={errors.trackingId?.message !== undefined} helperText={errors.trackingId?.message} />
                <Controller
                    name="dispatchedAt"
                    control={control}
                    defaultValue={new Date()}
                    rules={{ required: "Dispatched date is required!" }}
                    render={({ field }) => <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            {...field}
                            label="Dispatched Date"
                            inputFormat='dd/MM/yyyy'
                            renderInput={(params) => <TextField {...params} error={typeof errors.dispatchedAt?.message !== 'undefined'} helperText={errors.dispatchedAt?.message} fullWidth />}
                        />
                    </LocalizationProvider>} />
            </DialogContent>
            <Divider />
            <DialogContent>
                <Grid width="100%" container>
                    <Grid sx={{ paddingRight: .5 }} justifyContent='center' item sm={12} md={6}>
                        <Button disabled={dialogState.processing} fullWidth variant='contained' color='error' onClick={handleClose}>Cancel</Button>
                    </Grid>
                    <Grid sx={{ paddingLeft: .5 }} justifyContent='center' item sm={12} md={6}>
                        <Button disabled={dialogState.processing} type="submit" fullWidth variant='contained' color='success'>Dispatch Order</Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </form>
    </Dialog>
}
