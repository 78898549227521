import { Alert, Avatar, Box, Button, Grid, LinearProgress, TextField, Typography, useTheme } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import Helmet from "react-helmet";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Api from "../lib/api";
import { saveToLocalStorage, useAuth } from "../lib/util";


export default function Login() {
    const [renderZone, setRenderZone] = useState({
        loading: false,
        render: false,
        message: ""
    })
    const navigator = useNavigate()
    const isAuth = useAuth()
    const apiHelper = useMemo(() => new Api(), [])
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [loginState, setLoginState] = useState({
        error: '',
        processing: false,
    })
    const theme = useTheme()

    useEffect(() => {
        if (isAuth) {
            navigator("/", {
                replace: true
            })
        }
    }, [navigator, isAuth])

    const ping = useCallback(
        async () => {
            const response = await apiHelper.ping()
            if (response.ok && response.data) {
                setRenderZone({
                    loading: false,
                    render: true,
                    message: ""
                })
            } else {
                setRenderZone({
                    loading: false,
                    render: false,
                    message: response.message ?? "You are outside of allowed network zones!"
                })
            }
        },
        [apiHelper],
    )
    useEffect(() => {
        ping()
    }, [ping])





    const authenticateUser: SubmitHandler<any> = async (data) => {
        setLoginState(({ processing: true, error: '' }))
        const response = await apiHelper.login(data)
        if (response.ok) {
            await saveToLocalStorage("order_room_v4_token", response.data!.token)
            window.location.reload()
        } else {
            setLoginState(({ processing: false, error: response.message! }))
        }
    }

    const hasError = (inputName: string) => {
        return typeof errors[inputName] !== 'undefined' ? true : false
    }

    const getError = (inputName: string): string => {
        return errors[inputName]?.message?.toString() ?? ""
    }

    // console.log(errors)

    return (
        <>
            {
                renderZone.loading ? <>
                    <Helmet>
                        <title>Loading...</title>
                    </Helmet>
                    <Grid container alignItems='center' justifyContent='center' height="100vh">
                        <Box textAlign="center">
                            <Avatar src={'/assets/logo.png'} sx={{ height: 100, width: 100, backgroundColor: '#cda6db5c', mb: 2, padding: 1 }} />
                            <LinearProgress />
                            <Typography sx={{ mt: 1 }}>Loading...</Typography>
                        </Box>
                    </Grid>
                </> : renderZone.render ? <>
                    <Helmet>
                        <title>Login - Order Room</title>
                    </Helmet>
                    <Grid padding={3} container bgcolor={theme.palette.background.default} justifyContent='center' alignItems='center' height='100vh'>
                        <Grid maxWidth={450} item textAlign='center'>
                            {
                                loginState.error && <Alert sx={{
                                    marginBottom: 2
                                }} severity="error">{loginState.error}</Alert>
                            }
                            <img src="/assets/logo.png" alt="AR Ayurveda" height={100} width={100} />
                            <Typography variant='h4' color={theme.palette.text.primary}>Order Room</Typography>
                            <Typography variant='subtitle2' color={theme.palette.text.secondary}>AR Ayurveda</Typography>
                            <form onSubmit={handleSubmit(authenticateUser)}>
                                <TextField {...register("username", {
                                    required: "Username is required!"
                                })} sx={{
                                    marginTop: 2
                                }} autoFocus fullWidth label="Username" variant="outlined" error={hasError("username")} helperText={getError("username")} />
                                <TextField {...register("password", {
                                    required: "Password is required!"
                                })} sx={{
                                    marginTop: 2
                                }} fullWidth type='password' label="Password" variant="outlined" error={hasError("password")} helperText={getError("password")} />
                                <Button type='submit' sx={{
                                    marginTop: 2
                                }} variant='contained' disabled={loginState.processing} color='primary' fullWidth>{loginState.processing ? "Please wait..." : "Login"}</Button>
                            </form>
                        </Grid>
                    </Grid>
                </> : <>
                    <Helmet>
                        <title>Outside zone</title>
                    </Helmet>
                    <Grid container alignItems='center' justifyContent='center' height="100vh">
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <Avatar src={'/assets/logo.png'} sx={{ height: 100, width: 100, backgroundColor: '#cda6db5c', mb: 2, padding: 1 }} />
                            <Typography sx={{ mt: 1 }}>{renderZone.message}</Typography>
                        </Box>
                    </Grid>
                </>
            }
        </>
    )
}
