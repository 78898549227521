import React from 'react'
interface DarkModeContextData {
    enabled: boolean,
    setEnabled: (value: boolean) => void
}

const DarkModeContext = React.createContext<DarkModeContextData>({
    enabled: false,
    setEnabled: (value) => { }
})

export const DarkModeProvider = DarkModeContext.Provider
export const DarkModeConsumer = DarkModeContext.Consumer
export default DarkModeContext